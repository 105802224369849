import { Button, Checkbox, Col, Grid, Select, Text, TextInput, useMantineTheme } from '@mantine/core'
import { DatePicker, isSameDate } from '@mantine/dates'
import { useForm } from '@mantine/hooks'
import { CalendarIcon } from '@radix-ui/react-icons'
import React, { useState, useEffect, useContext } from 'react'
import { images } from '../assets'
import { AxiosFetch } from "../../AxiosFetch";
import { formatDate } from "../dateFormats";
import { useAddEvent } from '../useCalendarData';
import LoginContext from '../../../context/LoginContext'




const CalendarPopup = (
    { selectedStartDate, resources, modalControl }
) => {
  
    const url = window.location.href;
    const urlObject = new URL(url);
    const port = urlObject.port;
    console.log(port)
    const theme = useMantineTheme();
    const { mutate: addNewEvent } = useAddEvent();
    const axiosFetch = AxiosFetch();
    const {userId,...contex}=useContext(LoginContext);


    // var userName = sessionStorage.getItem("userId");
    var userName = userId;

    const [values, setValues] = useState([]);
    const [typeSelect, setTypeSelect] = useState(0);
    const [users, setUsers] = useState([]);
    const [userGroups, setUserGroups] = useState([]);

    const [addedUsers, setAddedUsers] = useState([]);
    const[depAndUser,setDepAndUser]=useState([]);

    const [depId,setDepId]=useState();

    const [userRows, setUserRows] = useState([]);
    const [group, setGroupRows] = useState([]);

    useEffect(() => {
        async function getDataUserAll() {
          const APIvalues = {
            customerGroups: [
              {
                groupId: 0,
              },
            ],
          };
    
        //   const response = await axiosFetch.post(`/userList`,JSON.stringify(APIvalues));
        //   setUserRows(response.data);
          const response2 = await axiosFetch.get(`/group/user`);
          setGroupRows(response2.data);
        }
    
        getDataUserAll();

    
      }, []);


    useEffect(() => {

        const groupIDs = group.map(group => `'${group.groupId}'`);

        axiosFetch.post(`../backend/cti-calendar/userAndDep/fetchUserDepMst.php?port=${port}&&groupIds=${groupIDs}`)
            .then(function (response) {
                setUsers([
                    { label: "-", value: "all" },
                    ...response.data[0]
                ]);
                setUserGroups(response.data[1]);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [group])

    useEffect(
    ()=>{



      axiosFetch.post(`../backend/cti-calendar/userAndDep/findDep.php?port=${port}`,JSON.stringify({userId:resources}))
          .then(function (response) {

              setDepId(response.data[0].group_id)

          })
          .catch(function (error) {
              console.log(error);
          });
    },[]
    )



    useEffect(() => {

        const groupIDs = group.map(group => `'${group.groupId}'`);

        axiosFetch.post(`../backend/cti-calendar/userAndDep/fetchUserFromDepMst.php?port=${port}&&groupIds=${groupIDs}`)
            .then(function (response) {
              //  setDepAndUser(response.data);

                setDepAndUser([
                    { dep:"-", depId:"all", value: "all",label: "-" },
                    ...response.data
                ]);


            })
            .catch(function (error) {
                console.log(error);
            });
    }, [group])


    useEffect(() => {
  //  console.log(depAndUser)
  }, [depAndUser])


    const form = useForm({
        initialValues: {
            eventType: 0,
            tempHolding: false,
            holiday: false,
            title: '',
            registrants: userName,
            schedule: [selectedStartDate.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        })],
            start: selectedStartDate.toLocaleTimeString(),
            end: new Date(selectedStartDate.getTime() + 2 * 60 * 60 * 1000).toLocaleTimeString(),
            allDay: false,
            dayRepeat: false,
            timeRepeat: false,
            repeatTill: formatDate(selectedStartDate),
            place: '',
            attendee: '',
            userGroup: null,
            user: null,
            registeredUser: resources
        },
        validationRules: {
          //  title: (value) => value.trim().length >= 2,
            registrants: (value) => value.trim().length >= 2,
          //  place: (value) => value.trim().length >= 2,
            groupId: (value) => /^(\s*|\d+)$/.test(value),
        }
    });

    const handleAllDay = (event) => {
        form.setFieldValue('allDay', event.currentTarget.checked)
        if (event.currentTarget.checked) {
            form.setFieldValue('start', '')
            form.setFieldValue('end', '')
        }
        else {
            form.setFieldValue('start', selectedStartDate.toLocaleTimeString())
            form.setFieldValue('end', new Date(selectedStartDate.getTime() + 2 * 60 * 60 * 1000).toLocaleTimeString())
        }
    }

    const handleTempHolding = (event) => {
        form.setFieldValue('tempHolding', event.currentTarget.checked)
        if (event.currentTarget.checked) {

              if(form.values.holiday) {  form.setFieldValue('title', '【仮】【休日】') }
              else{form.setFieldValue('title', '【仮】') }

        }
        else {
              if(form.values.holiday){   form.setFieldValue('title', '【仮】') }
              else{form.setFieldValue('title', '') }

        }
    }

    const handleHoliday = (event) => {

        form.setFieldValue('holiday', event.currentTarget.checked)
        if (event.currentTarget.checked) {

            //form.setFieldValue('tempHolding', false)
            if(form.values.tempHolding){ form.setFieldValue('title', '【仮】【休日】')    }
            else{form.setFieldValue('title', '【休日】')}


            form.setFieldValue('allDay', event.currentTarget.checked)
            form.setFieldValue('start', '')
            form.setFieldValue('end', '')
        }
        else {
          if(form.values.tempHolding){ form.setFieldValue('title', '【仮】')    }
          else{form.setFieldValue('title', '')}

            form.setFieldValue('allDay', event.currentTarget.checked)
            form.setFieldValue('start', selectedStartDate.toLocaleTimeString())
            form.setFieldValue('end', new Date(selectedStartDate.getTime() + 2 * 60 * 60 * 1000).toLocaleTimeString())
        }
    }

    const inputFormat = values
        .map((date) => new Date(date).toLocaleDateString("en-US"))
        .join(", ");

    const dayStyle = (date) => {
        if (values.some((day) => isSameDate(date, day))) {
            return {
                backgroundColor: theme.colors.blue[0],
                color: theme.colors.blue[9]
            };
        }
        return null;
    };

    const handleDayPick = (value) => {
        const formattedDate = formatDate(value);
        setValues((current) => {
            if (current) {
                if (current.some((day) => isSameDate(value, day))) {
                    return current.filter((day) => !isSameDate(value, day));
                }
            }

            let formDateValue = []
            current.map((dateValue) => {
                formDateValue.push(formatDate(dateValue))
            })

            form.setFieldValue('schedule', [...formDateValue, formattedDate]);

            return [...current, value];
        });
    };

    const handleAddEvent = () => {

        var schedule = form.values.schedule.toString();
        console.log(form.values.schedule)



      const updatedUser=[];
      //   console.log(addedUsers.length)
         for (let i = 0; i < addedUsers.length; i++) {
        //   alert(addedUsers[i].userGroupValue)

        if(addedUsers[i].userValue!="all"){
          updatedUser.push({
              userGroupValue: addedUsers[i].userGroupValue,
              userGroupLabel: addedUsers[i].userGroupLabel,
              userValue: addedUsers[i].userValue,
              userLabel: addedUsers[i].userLabel,
          })
        }
        else {

            //   const filterUser=depAndUser.filter(depAndUser=> (depAndUser.depId.includes(addedUsers[i].userGroupValue)))
              const filterUser = depAndUser.filter(item =>
                item.depId != null && item.depId.toString().includes(addedUsers[i].userGroupValue)
              );
              
              for (let j = 0; j < filterUser.length; j++)
                {
                updatedUser.push({
                    userGroupValue: filterUser[j].depId,
                    userGroupLabel: filterUser[j].dep,
                    userValue: filterUser[j].value,
                    userLabel: filterUser[j].label,
                })
              }
        }
         }


         if(form.values.userGroup!=null && form.values.user!=null){

                   if(form.values.user!="all"){

                    //   const filterUser=depAndUser.filter(depAndUser=> (depAndUser.depId.includes(form.values.userGroup))&& (depAndUser.value.includes(form.values.user)))
                      const filterUser = depAndUser.filter(item => 
                        item.depId && item.depId.toString().includes(form.values.userGroup) && 
                        item.value.includes(form.values.user)
                    );
                    
                      updatedUser.push({
                           userGroupValue: filterUser[0].depId,
                           userGroupLabel: filterUser[0].dep,
                           userValue: filterUser[0].value,
                           userLabel: filterUser[0].label,
                       })
                     }
                     else {

                        //    const filterUser=depAndUser.filter(depAndUser=> (depAndUser.depId.includes(form.values.userGroup)))
                        const filterUser = depAndUser.filter(item =>
                            item.depId != null && item.depId.toString().includes(form.values.userGroup)
                          );
                             
                        for (let j = 0; j < filterUser.length; j++)
                             {

                             updatedUser.push({
                                 userGroupValue: filterUser[j].depId,
                                 userGroupLabel: filterUser[j].dep,
                                 userValue: filterUser[j].value,
                                 userLabel: filterUser[j].label,
                             })
                           }
                     }
         }



// console.log(updatedUser)


         modalControl(false);
      //   alert(form.values.userGroup)
        // alert(form.values.user)



    const eventParam = {
        allDay: form.values.allDay,
        attendee: form.values.attendee,
        dayRepeat: form.values.dayRepeat,
        end: form.values.end,
        eventType: form.values.eventType,
        place: form.values.place,
        registrants: form.values.registrants,
        repeatTill: form.values.repeatTill.toString(),
        schedule: schedule,
        start: form.values.start,
        tempHolding: form.values.tempHolding,
        holiday: form.values.holiday,
        title: form.values.title,
        department: depId,
        registeredUser: form.values.registeredUser,
        registeredBy: userName,
        addedUsers:updatedUser,
        customerCode:"顧客情報が無い予定",
        port:port
        
    }
    console.log(eventParam,port)
  addNewEvent(eventParam,port);
  alert('保存しました。');





    }

    const EventTypeButton = ({ type, name, icon }) => {
        return (
            <Button
                variant={typeSelect === type ? 'filled' : 'outline'}
                size={25}
                style={{ width: 70, padding: 0 }}
                onClick={() => {
                    form.setFieldValue('eventType', type);
                    typeSelect === type ? setTypeSelect(0) : setTypeSelect(type);
                }}
            >
                <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                    <img src={images[icon]} style={{ height: 25 }} />
                    <Text>{name}</Text>
                </div>
            </Button>
        )
    }

    const addUser = () => {
        if (form.values.userGroup && form.values.user) {
            const userGroupName = userGroups.find(user => user.value === form.values.userGroup);
            const userName = users.find(user => user.value === form.values.user);

            if(userName.value=="all"){
            //  alert(userName.value)

              setAddedUsers(prev => ([
                  ...prev,
                  {
                      userGroupValue: userGroupName.value,
                      userGroupLabel: userGroupName.label,
                      userValue: userName.value,
                      userLabel: userName.label,
                  }
              ]))


              /*
              const filterUser=depAndUser.filter(depAndUser=> (depAndUser.depId.includes(userGroupName.value)))
              for (let i = 0; i < filterUser.length; i++) {

                setAddedUsers(prev => ([
                    ...prev,
                    {
                        userGroupValue: filterUser[i].depId,
                        userGroupLabel: filterUser[i].dep,
                        userValue: filterUser[i].value,
                        userLabel: filterUser[i].label,
                    }
                ]))

              }
              */


            }
            else{
              setAddedUsers(prev => ([
                  ...prev,
                  {
                      userGroupValue: userGroupName.value,
                      userGroupLabel: userGroupName.label,
                      userValue: userName.value,
                      userLabel: userName.label,
                  }
              ]))
            }



            form.values.userGroup = null;
            form.values.user = null;
        }
    }

    const removeUser = (userInfo) => {
        setAddedUsers(prev => (
            prev.filter(user => user.userValue !== userInfo.userValue)
        ))
    }

    return (
        <div>
            <form onSubmit={form.onSubmit(handleAddEvent)}>
                <Grid align='center'>
                    <Col span={3}>
                        <Text>予定種別</Text>
                    </Col>
                    <Col span={8}>
                        <Grid>
                            <Col span={2} >
                                <EventTypeButton type={1} name='電話' icon='phoneForward' />
                            </Col>
                            <Col span={2}>
                                <EventTypeButton type={2} name='工事' icon='build' />
                            </Col>
                            <Col span={2}>
                                <EventTypeButton type={3} name='訪問' icon='transferStation' />
                            </Col>
                            <Col span={2}>
                                <EventTypeButton type={4} name='会議' icon='meetingRoom' />
                            </Col>
                            <Col span={2}>
                                <EventTypeButton type={5} name='休み' icon='localCafe' />
                            </Col>
                            <Col span={2}>
                                <EventTypeButton type={6} name='その他' icon='reportProblem' />
                            </Col>
                        </Grid>
                    </Col>
                </Grid>

                <Grid align='center'>
                    <Col span={1}></Col>
                    <Col span={4}>
                        <Checkbox
                            label="スケジュール仮押さえ"
                            style={{ padding: 5 }}
                            checked={form.values.tempHolding}
                            //  onChange={(event) => form.setFieldValue('tempHolding', event.currentTarget.checked)}
                            onChange={handleTempHolding}
                        />
                    </Col>
                    <Col span={3}>
                        <Checkbox
                            label="休日登録"
                            style={{ padding: 5 }}
                            checked={form.values.holiday}
                            onChange={handleHoliday}
                        //  onChange={(event) => form.setFieldValue('holiday', event.currentTarget.checked)}
                        />
                    </Col>
                </Grid>

                <Grid align='center'>
                    <Col span={2}>
                        <Text>タイトル</Text>
                        <Text>(問合せ件名）</Text>
                    </Col>
                    <Col span={9}>
                        <TextInput

                            value={form.values.title}
                            onChange={(event) => form.setFieldValue('title', event.currentTarget.value)}
                            onFocus={() => form.setFieldError('title', false)}
                            error={form.errors.title && 'Title should include at least 2 characters'}
                        />
                    </Col>
                </Grid>
                <Grid align='center'>
                    <Col span={2}>
                        <Text>登録者</Text>
                    </Col>
                    <Col span={9}>
                        <TextInput
                            disabled
                            value={form.values.registrants}
                            onChange={(event) => form.setFieldValue('registrants', event.currentTarget.value)}
                            onFocus={() => form.setFieldError('registrants', false)}
                            error={form.errors.registrant && 'Registrants should include at least 2 characters'} />
                    </Col>
                </Grid>

                <Grid align='center'>
                    <Col span={2}>
                        <Text>スケジュール</Text>
                    </Col>
                    <Col span={10}>
                        <Grid align='center'>
                            <Col span={4}>
                                <DatePicker
                                    closeCalendarOnChange={false}
                                    inputFormat={inputFormat}
                                    value={values.length > 0 ? values[0] : selectedStartDate}
                                    onChange={handleDayPick}
                                    dayStyle={dayStyle}
                                    onClear={() => {
                                        form.setFieldValue('schedule', []);
                                        setValues([]);
                                    }}
                                    dropdownType="modal"
                                    multiline
                                    styles={{
                                        selected: {
                                            backgroundColor: "transparent",
                                            color: "unset"
                                        }
                                    }}
                                    icon={<CalendarIcon style={{ color: 'black' }} />}
                                />
                            </Col>
                            <Col span={1}>
                                <Text>開始</Text>
                            </Col>
                            <Col span={2}>
                                <input type='time'
                                style={{'width':'100px'}}
                                    defaultValue={selectedStartDate.toLocaleTimeString()}
                                    value={form.values.start}
                                    onChange={(e) => form.setFieldValue('start', e.currentTarget.value)}
                                />
                            </Col>
                            <Col span={1}>
                                <Text>終了</Text>
                            </Col>
                            <Col span={2}>
                                <input type='time'
                                style={{'width':'100px'}}
                                    defaultValue={new Date(selectedStartDate.getTime() + 2 * 60 * 60 * 1000).toLocaleTimeString()}
                                    value={form.values.end}
                                    onChange={(e) => form.setFieldValue('end', e.currentTarget.value)}
                                />
                            </Col>
                            <Col span={2}>
                                <Checkbox label="終日" checked={form.values.allDay} onChange={handleAllDay} />
                            </Col>
                        </Grid>
                        <Grid align='center'>
                            <Col span={3}>
                                <Checkbox label="曜日リピート" checked={form.values.dayRepeat} onChange={(event) => form.setFieldValue('dayRepeat', event.currentTarget.checked)} />
                            </Col>
                            {/*
                            <Col span={3}>
                                <Checkbox label="時刻リピート" checked={form.values.timeRepeat} onChange={(event) => form.setFieldValue('timeRepeat', event.currentTarget.checked)} />
                            </Col>
                            */}
                            <Col span={2} style={{ padding: 0 }}>
                                <Text>リピート終点</Text>
                            </Col>
                            <Col span={3}>
                                <DatePicker
                                    dropdownType="modal"
                                    defaultValue={selectedStartDate}
                                    onChange={(value) => {
                                        form.setFieldValue('repeatTill', formatDate(value))
                                    }}
                                    icon={<CalendarIcon style={{ color: 'black' }} />}
                                />
                            </Col>
                        </Grid>
                    </Col>
                </Grid>

                <Grid align='center'>
                    <Col span={2}>
                        <Text>場所</Text>
                    </Col>
                    <Col span={9}>
                        <TextInput
                            value={form.values.place}
                            onChange={(event) => form.setFieldValue('place', event.currentTarget.value)}
                            onFocus={() => form.setFieldError('place', false)}
                            error={form.errors.place && 'Place should include at least 2 characters'}
                        />
                    </Col>
                </Grid>
                <Grid >
                    <Col span={2} style={{ paddingTop: 20 }}>
                        <Text>出席者</Text>
                    </Col>
                    <Col span={10}>
                        <Grid align='center'>
                            <Col span={1}>
                                <Button
                                    style={{ backgroundColor: '#0844A4' }}
                                    onClick={addUser}
                                >+</Button>
                            </Col>
                            <Col span={3}>
                                <Select

                                    style={{ padding: 10 }}
                                    placeholder="部門名"
                                    data={userGroups}
                                    defaultValue={form.values.userGroup}
                                    onChange={value =>
                                        {
                                         form.setFieldValue('user', '')
                                         form.setFieldValue('userGroup', value)
                                        }}
                                />
                            </Col>
                            <Col span={3}>
                                <Select

                                    style={{ padding: 10 }}
                                    placeholder=""
                                    data={depAndUser.filter(depAndUser => 
                                        depAndUser.depId === form.values.userGroup || 
                                        depAndUser.depId.toString().includes("all")
                                    )}
                                    
                                    // data={depAndUser.filter(depAndUser=> (depAndUser.depId===(form.values.userGroup)) || (depAndUser.depId.includes("all"))  ) }
                                     value={form.values.user}
                                    onChange={value => form.setFieldValue('user', value)}
                                />
                            </Col>
                        </Grid>
                        {addedUsers.map((userInfo, index) => (
                            <Grid key={index}>
                                <Col span={1} >
                                    <Button
                                        style={{ backgroundColor: 'red' }}
                                        onClick={() => removeUser(userInfo)}
                                    >X</Button>
                                </Col>
                                <Col span={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Text>{userInfo.userGroupLabel}</Text>
                                </Col>
                                <Col span={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Text>{userInfo.userLabel}</Text>
                                </Col>
                            </Grid>
                        ))}
                    </Col>
                </Grid>
                <Grid>
                    <Col span={9}></Col>
                    <Col span={2}>
                        <Button
                            fullWidth
                            radius='xl'
                            style={{ backgroundColor: '#0844A4' }}
                            type='submit'
                        >
                            保存
                        </Button>
                    </Col>
                </Grid>
            </form>

        </div>
    )
}

export default CalendarPopup
