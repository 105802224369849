import { useMutation, useQuery, useQueryClient } from "react-query";
import { AxiosFetch } from "../AxiosFetch";

const axiosFetch = AxiosFetch();


const addEvent = (eventData,port) => {

     console.log(eventData['port']);
     return axiosFetch.post(`../backend/cti-calendar/Calendar/add_google_calendar.php?port=${eventData['port']}`, JSON.stringify(eventData))
}


export const useAddEvent = () => {
    const queryClient = useQueryClient();

    return useMutation(
        (eventParam) => addEvent({...eventParam}),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['userEvent']);
            },
        }
    )
}



const editEvent = (eventData,port) => {
  console.log(eventData);
    return axiosFetch.post(`../backend/cti-calendar/Calendar/updateEvent.php?port=${eventData['port']}`, JSON.stringify(eventData))
}



export const useEditEvent = () => {
    const queryClient = useQueryClient();

    return useMutation(
        (eventParam) => editEvent({...eventParam}),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['userEvent']);
            },
        }
    )
}


const fetchUserEventData = ({ queryKey }) => {

    const { view,daysInterval, startDate, iteration, myEvent, myUserId, userName, department , port } = queryKey[1];
 
if(view=='month'){
  return axiosFetch.get(`../backend/cti-calendar/month-view-googlecalendar.php?day=${daysInterval}&&startDate=${startDate}&&iteration=${iteration}&&mySchedule=${myEvent}&&myUserId=${myUserId}&&userName=${userName}&&department=${department}&&port=${port}`)

}
else{
  return axiosFetch.get(`../backend/cti-calendar/fetchgooglecalendar.php?day=${daysInterval}&&startDate=${startDate}&&iteration=${iteration}&&mySchedule=${myEvent}&&myUserId=${myUserId}&&userName=${userName}&&department=${department}&&port=${port}`)

}
    }

export const useUserEventData = (dataFields, onSuccess, onError) => {
    return useQuery(
        ['userEvent', dataFields],
        fetchUserEventData,  {refetchOnWindowFocus: false ,
        onSuccess,
        onError
    })
}



const fetchResources = ({ queryKey }) => {


    const { daysInterval, startDate, iteration, myEvent, myUserId, userName, department, port ,userRows } = queryKey[1];
console.log(queryKey[1])


// Extract user ID

// Extract user IDs and wrap them in single quotes
const userIds = userRows.map(user => `'${user.userId}'`);

console.log(userIds); // Output: ['1', 'admin', '3']

    return axiosFetch.get(`../backend/cti-calendar/fetchResourcesNew.php?day=${daysInterval}&&startDate=${startDate}&&iteration=${iteration}&&mySchedule=${myEvent}&&myUserId=${myUserId}&&userName=${userName}&&department=${department}&&port=${port}&&userRows=${userIds}`)
}

export const useFetchResources = (dataFields, onSuccess, onError) => {
    return useQuery(
        ['userResources', dataFields],
        fetchResources, {refetchOnWindowFocus: false ,
        onSuccess,
        onError
    })
}

const deleteEventData = (id,port) => {
    return axiosFetch.delete(`../backend/cti-calendar/deleteEvent.php?port=${id['port']}`, {
        data: JSON.stringify(id)
         
    })
}

export const useDeleteEventData = () => {
    const queryClient = useQueryClient();

    return useMutation(deleteEventData, {
        onSuccess: () => {
            queryClient.invalidateQueries('userEvent');
        }

    })
}




const bulkDeleteEventData = (id,port) => {
    return axiosFetch.delete(`../backend/cti-calendar/Calendar/deleteBulkEvents.php?port=${id['port']}`, {
        data: JSON.stringify(id)
    })
}

export const useBulkDeleteEventData = () => {
    const queryClient = useQueryClient();

    return useMutation(bulkDeleteEventData, {
        onSuccess: () => {
            queryClient.invalidateQueries('userEvent');
        }

    })
}
