import React from 'react'
import { Button} from '@mantine/core'
import { AxiosFetch } from "../../AxiosFetch";
import { useNavigate } from "react-router";
const ConfirmPopup = (
    {data,modalControl4,userId}
) => {

    console.log(data)
  const url = window.location.href;
  const urlObject = new URL(url);
  const port = urlObject.port;
  const navigate = useNavigate();
  const axiosFetch = AxiosFetch();


  const deleteEventData = () => {

//     // console.log(data)
//    deleteEvent(data,port);

axiosFetch.post(`../backend/cti-calendar/hideEvents.php?port=${port}`, JSON.stringify({
    ticketId:data.extendedProps.ticketId,
    google_calendar_id:data.extendedProps.google_calendar_id,
    myUserId:userId
  
  }))

    .then(function (response) {
            console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
    
        
  navigate("/customerDetail", {
    state: {
      customerId: data.extendedProps.code,
      showTopicId: data.extendedProps.inquiryLogId,
      eventType: data.extendedProps.eventType,
      eventId: data.extendedProps.eventId,
      showModal: true,
      ticketId: data.extendedProps.ticketId,
    },
  });


  modalControl4(false)

  }

  const handleCancel=()=>{

     // User clicked "Cancel" (no) in the confirmation dialog
    // Show alert or handle as needed
    // alert("You chose not to hide.");
    navigate("/customerDetail", {
        state: {
          customerId: data.extendedProps.code,
          showTopicId: data.extendedProps.inquiryLogId,
          eventType: data.extendedProps.eventType,
          eventId: data.extendedProps.eventId,
          showModal: true,
          ticketId: 0,
        },
      });

    modalControl4(false)
  }

    return (
      <>
    <h6>
        この予定を対応しますか？<br />
        「はい」を選ぶとカレンダーからチケットが削除されます
        </h6>


      <Button onClick={()=>deleteEventData()} color='teal'>はい、このまま対応します</Button> &emsp;
      <Button onClick={handleCancel} color='red'>いいえ、閲覧だけです</Button>


  </>  )



}

export default ConfirmPopup;
