/* eslint-disable */
import React, { useState, useEffect, useRef, useCallback } from "react";
import FullCalendar from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import dayGridPlugin from '@fullcalendar/daygrid'
import { Button, Modal, Center, Text, Grid, Select } from "@mantine/core";
import { DatePicker } from '@mantine/dates';
import { useForm } from "@mantine/form";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { formatDate } from './dateFormats';
import $ from "jquery"; //0707追記分
import { useDrop } from "react-dnd";

import { Cross1Icon, Cross2Icon } from "@radix-ui/react-icons";
import interactionPlugin from "@fullcalendar/interaction";
import { useNavigate } from "react-router";
import { images } from "./assets";
import events from "./events.json";
import resources from "./resources.json";
import "./styles.css";
import CalendarPopup from "./CalendarPopup/CalendarPopup";
import EditCalendarPopup from "./EditCalendarPopup/EditCalendarPopup";
import DeletePopup from "./DeletePopup/DeletePopup";
import ConfirmPopup from "./ConfirmPopup/ConfirmPopup";

import { useUserEventData, useFetchResources } from "./useCalendarData";
import { AxiosFetch } from "../AxiosFetch";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import { useDrag } from "react-dnd";
import { useContext } from "react";
import LoginContext from "../../context/LoginContext";
import {  parse } from 'date-fns'; // You can use date-fns for date formatting and parsing
import { useMediaQuery } from "@mantine/hooks";


const Calendar = () => {
  const { userId, ...context } = useContext(LoginContext);
  // console.log(userId)
const [view, setView] = useState('week');
const matches = useMediaQuery('(min-width: 400px)');
const deviceWidth = window.innerWidth;
const [calendarRender, setCalendarRender] = useState(1)
const [calendarTitle, setCalendarTitle] = useState()

var existingCalendarData ={}
  const axiosFetch2 = AxiosFetch();
  useEffect(() => {
    context.setPageTitle("Calendar");
  }, []);
  const style = {
    position: "absolute",
    zIndex: 10,
    border: "1px solid black",
    borderRadius: 5,
    backgroundColor: "white",
    padding: "1.5rem 2rem",
    cursor: "move",
  };
  const DraggableBox = ({ id, left, top, hideSourceOnDrag, children }) => {
    const [position, setPosition] = useState({ left, top });

    const [{ isDragging }, drag] = useDrag(
      () => ({
        type: "box",
        item: { id, left: position.left, top: position.top },
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),
        drag: (item, monitor) => {
          const clientOffset = monitor.getClientOffset();
          if (clientOffset) {
            const left =
              clientOffset.x - monitor.getInitialSourceClientOffset().x;
            const top =
              clientOffset.y - monitor.getInitialSourceClientOffset().y;
            setPosition({ left, top });
          }
        },
      }),
      [id, position]
    );

    if (isDragging && hideSourceOnDrag) {
      return <div ref={drag} />;
    }
    return (
      <div
        className="box"
        ref={drag}
        style={{ ...style, left: position.left, top: position.top }}
        data-testid="box"
      >
        {children}
      </div>
    );
  };


  function clearCalendarLocalStorage() {
    localStorage.removeItem('calendar')
    window.location.reload();

  }

  const axiosFetch = AxiosFetch();
  const [userRows, setUserRows] = useState([]);
  const [group, setGroupRows] = useState([]);
  const[ projectData,setProjectData]=useState([]);


  useEffect(() => {
    async function getProject() {
        const project =  await axiosFetch.get(`/project`);
        console.log("project",project.data)
        setProjectData(project.data)
        
      }
      getProject();
    
      
  }, []);

  
  useEffect(() => {
    async function getDataUserAll() {
      const APIvalues = {
        customerGroups: [
          {
            groupId: 0,
          },
        ],
      };

      const response = await axiosFetch.post(`/userList`,JSON.stringify(APIvalues));
      setUserRows(response.data);
      const response2 = await axiosFetch.get(`/group/user`);
      setGroupRows(response2.data);
    }

    getDataUserAll();
    function convertTZ(date, tzString) {
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          { timeZone: tzString }
        )
      );
    }
    const date = new Date();
    var date1 = convertTZ(date, "Asia/Tokyo");
    date1 = date1.toLocaleTimeString().slice(0, 5);

    var date2 = convertTZ(date, "Australia/Adelaide");
    date2 = date2.toLocaleTimeString().slice(0, 5);

  }, []);
  const[ticketId,setTicketId]=useState(0)
  const[googleCalendarId,setGoogleCalendarId]=useState(null)
  const [calendarDate, setCalendarDate] = useState(
    new Date().toISOString().slice(0, 10)
  );

  const url = window.location.href;
  const urlObject = new URL(url);
  const port = urlObject.port;

  const form = useForm({ initialValues: { userName: "", department: "" } });
  const form2 = useForm({ initialValues: { date: "" } });
  
  
  useEffect(() => {
  const elementsToRemove = document.querySelectorAll('.fc-timeline-header-row-chrono');

  elementsToRemove.forEach((element) => {
    element.remove();
  });


})


    useEffect(() => {
      // Retrieve values from localStorage
      const localStorageCalendar = JSON.parse(localStorage.getItem('calendar'));
      // console.log(localStorageCalendar)
      const localStorageMyEvent = localStorage.getItem('myEvent');

      if (localStorageCalendar) {
        // Use values from localStorage
        if (localStorageCalendar.calendarDate) {
          setCalendarDate(localStorageCalendar.calendarDate);
        }
        if (localStorageCalendar.startDate && !isNaN(Date.parse(localStorageCalendar.startDate))) {
  
          setStartDate(localStorageCalendar.startDate);
          setCalendarDate(localStorageCalendar.startDate)
        
          setClearDateInput(true);
          
          form2.setFieldValue("date", localStorageCalendar.startDate);
          
        }
        if (localStorageCalendar.department) {
          // alert(localStorageCalendar.department)
          form.setFieldValue('department', localStorageCalendar.department);
        }
        if (localStorageCalendar.userName) {
          form.setFieldValue('userName', localStorageCalendar.userName);
        }
        if (localStorageCalendar.handleInterval > -1) {
          setDaysInterval(localStorageCalendar.handleInterval);
            form3.setFieldValue("timeInterval", localStorageCalendar.handleInterval);
            
        }
        if (localStorageCalendar.iteration === 'prev' || localStorageCalendar.iteration === 'next') {
          setIteration(localStorageCalendar.iteration);
        }
        if (localStorageCalendar.myEvent !== undefined) {
          setMyEvent(localStorageCalendar.myEvent);
        }
      }

    }, [daysInterval]);
    
  const form3 = useForm({ initialValues: { timeInterval: "" } });
  const navigate = useNavigate();
  const [daysInterval, setDaysInterval] = useState(7);

  const [startDate, setStartDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [endDate, setEndDate] = useState(new Date().toISOString().slice(0, 10));

  const [iteration, setIteration] = useState("");
  const [myEvent, setMyEvent] = useState(false);
  const [clearDateInput, setClearDateInput] = useState(false);

  const [myUserId,setMyUserId] = useState(userId);

  const [resourcesState, setResourcesState] = useState([]);
  const [eventState, setEventState] = useState([]);

  const refMySchedule = useRef("false");

  const [filterUser, setFilterUser] = useState([]);
  const [filterGroup, setFilterGroup] = useState([]);
  const [arg, setEventArgs] = useState();

  const [boxes, setBoxes] = useState({
    a: { top: 300, left: 350, title: "Calendar popup" },
  });

  const moveBox = useCallback(
    (id, left, top) => {
      setBoxes({
        a: { top: top, left: left, title: "Calendar popup" },
      });
    },
    [boxes, setBoxes]
  );
  const [, drop] = useDrop(
    () => ({
      accept: "box",
      drop(item, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset();
        const left = Math.round(item.left + delta.x);
        const top = Math.round(item.top + delta.y);
        moveBox(item.id, left + 10, top + 10);
        return undefined;
      },
    }),
    [moveBox]
  );

  const handleLink = (link) => {
    navigate(link);
  };
  
  let click = useRef(false).current;

  useEffect(() => {

const groupIDs = group.map(group => `'${group.groupId}'`);


    axiosFetch
      .post(
        `../backend/cti-calendar/userAndDep/fetchUserDepMst.php?port=${port}&&groupIds=${groupIDs}`
      )

      .then(function (response) {
        setFilterUser(response.data[0]);
        setFilterGroup(response.data[1]);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [group]);

  const [opened, setOpened] = useState(false);
  const [editPopupOpened, setEditPopupOpened] = useState(false);
  const [deletePopupOpened, setDeletePopupOpened] = useState(false);
  const [confirmPopupOpened, setConfirmPopupOpened] = useState(false);
  const [eventId, setEventId] = useState(0);
  const [eventGroupId, setEventGroupId] = useState(0);
  const [eventRepeat, setEventRepeat] = useState("false");
  const [existAttendees, setExistAttendees] = useState("false");

  const [selStartDate, setSelStartDate] = useState(new Date());
  const [resourcesData, setResourcesData] = useState();
  const [userEditEventData, setUserEditEventData] = useState();

  useEffect(() => {
    var theNewEndDate = new Date(startDate);
    theNewEndDate.setDate(theNewEndDate.getDate() + daysInterval - 1);
    theNewEndDate = theNewEndDate.toISOString().slice(0, 10);
    setEndDate(theNewEndDate);
  });

  let newDate = new Date();
  const currentdate = [];
  currentdate[2] = newDate.getDate();
  currentdate[1] = newDate.getMonth() + 1;
  currentdate[0] = newDate.getFullYear();

  const changeStartDate = (data) => {
      setCalendarRender(Math.random())
    var theNewStartDate = new Date(startDate);
    var theNewEndDate = new Date(endDate);

    //setEndDate(theNewStartDate)

    var days = parseInt(daysInterval);

    if (data == "prev") {
      theNewStartDate.setDate(theNewStartDate.getDate() - days);

      theNewEndDate.setDate(theNewEndDate.getDate() - days);

      theNewStartDate = theNewStartDate.toISOString().slice(0, 10);
      theNewEndDate = theNewEndDate.toISOString().slice(0, 10);
      //alert(theNewEndDate)
    }

    if (data == "next") {
      theNewStartDate.setDate(theNewStartDate.getDate() + days);
      theNewEndDate.setDate(theNewStartDate.getDate() + (days - 1));

      theNewStartDate = theNewStartDate.toISOString().slice(0, 10);
      theNewEndDate = theNewEndDate.toISOString().slice(0, 10);
    }
    
    
    if (data == 'nextday') {

      theNewStartDate.setDate(theNewStartDate.getDate() + 1);
      theNewEndDate.setDate(theNewStartDate.getDate() + (1 - 1));

      theNewStartDate = theNewStartDate.toISOString().slice(0, 10)
      theNewEndDate = theNewEndDate.toISOString().slice(0, 10)
    }
    if (data == 'prevday') {

      theNewStartDate.setDate(theNewStartDate.getDate() - 1);
      theNewEndDate.setDate(theNewEndDate.getDate() - (1));
      theNewStartDate = theNewStartDate.toISOString().slice(0, 10)
      theNewEndDate = theNewEndDate.toISOString().slice(0, 10)

    }
    
    if (data == 'nextmonth') {

        theNewStartDate.setDate(theNewStartDate.getDate() + 31);
        theNewEndDate.setDate(theNewStartDate.getDate() + (31 - 1));
        theNewStartDate = theNewStartDate.toISOString().slice(0, 10)
        theNewEndDate = theNewEndDate.toISOString().slice(0, 10)
      }

    if (data == 'prevmonth') {

      theNewStartDate.setDate(theNewStartDate.getDate() - 31);
      theNewEndDate.setDate(theNewEndDate.getDate() - (31));
      theNewStartDate = theNewStartDate.toISOString().slice(0, 10)
      theNewEndDate = theNewEndDate.toISOString().slice(0, 10)
      //alert(theNewEndDate)
    }
    
    setCalendarDate(theNewStartDate);
    setStartDate(theNewStartDate);
    setEndDate(theNewEndDate);

    setIteration(data);
    
    
    // Retrieve the existing calendar data from localStorage
     existingCalendarData = JSON.parse(localStorage.getItem('calendar')) || {};

    // Update the properties inside the 'calendar' object
    existingCalendarData.startDate = theNewStartDate;
    existingCalendarData.calendarDate = theNewStartDate;
    existingCalendarData.endDate = theNewEndDate;
    existingCalendarData.iteration = data;

    // Store the updated calendar data back in localStorage
    localStorage.setItem('calendar', JSON.stringify(existingCalendarData));



  };
  const onSuccess = (data) => {

    if(data.data!=''){
      setEventState(data.data);
    }
    else{
      setEventState([]);
    }

    
    console.log(data);
  };

  const onError = (error) => {
    console.log({ error });
  };

  const onSuccess1 = (data) => {
    console.log("changed");

    if(data.data!=''){
      setResourcesState(data.data);
    }
    else{
      setResourcesState([]);
    }
    
  };

  const onError1 = (error) => {
    console.log({ error });
  };

  const fontSize = window.innerWidth < 1000 ? 20 : null;
  const resourceFontSize = window.innerWidth < 1000 ? 30 : null;

  const { data: resourceData } = useFetchResources(
    {
      userRows:userRows,
      // daysInterval,
      startDate,
      // iteration,
      myEvent,
      myUserId:myUserId,
      userName: form.values.userName,
      department: form.values.department,
      port: port,
    },
    onSuccess1,
    onError1
  );

  const { data: eventData } = useUserEventData(
    {
      view,
      daysInterval,
      startDate,
      iteration,
      myEvent,
      myUserId:myUserId,
      userName: form.values.userName,
      department: form.values.department,
      port: port,
    },
    onSuccess,
    onError
  );

  function eventDetails(eventInfo) {
    if (eventInfo.event.extendedProps.starttime) {
      if (eventInfo.event.extendedProps.registeredVia == "calendar"|| eventInfo.event.extendedProps.registeredVia == "googlecalendar") {
        return (
          <>
            <div
              onClick={handleEditEvent}
              style={{ textAlign: "center", cursor: "pointer" }}
            >
              {eventInfo.event.extendedProps.eventType == 1 ? (
                <Button
                  variant="none"
                  size={5}
                  onClick={() => {
                    if (eventInfo.event.extendedProps.code != "--") {
                      navigate("/customerDetail", {
                        state: {
                          customerId: eventInfo.event.extendedProps.code,
                          inqNum: eventInfo.event.extendedProps.inquiryLogId,
                          eventType: eventInfo.event.extendedProps.eventType,
                          ticketId: eventInfo.event.extendedProps.ticketId,
                          topicId:eventInfo.event.extendedProps.topicId,
                          google_calendar_id:eventInfo.event.extendedProps.google_calendar_id
                        },
                      });
                    }
                  }}
                >
                  <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                    <img src={images["phoneForward"]} style={{ height: 20 }} />
                  </div>
                </Button>
              ) : null}

              {eventInfo.event.extendedProps.eventType == 3 ? (
                <Button
                  variant="none"
                  size={5}
                  onClick={() => {
                    if (eventInfo.event.extendedProps.code != "--") {
                      navigate("/customerDetail", {
                        state: {
                          customerId: eventInfo.event.extendedProps.code,
                          inqNum: eventInfo.event.extendedProps.inquiryLogId,
                          eventType: eventInfo.event.extendedProps.eventType,
                          ticketId: eventInfo.event.extendedProps.ticketId,
                          topicId:eventInfo.event.extendedProps.topicId
                        },
                      });
                    }
                  }}
                >
                  <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                    <img
                      src={images["transferStation"]}
                      style={{ height: 20 }}
                    />
                  </div>
                </Button>
              ) : null}

              {eventInfo.event.extendedProps.eventType == 4 ? (
                <Button
                  variant="none"
                  size={5}
                  onClick={() => {
                    if (eventInfo.event.extendedProps.code != "--") {
                      navigate("/customerDetail", {
                        state: {
                          customerId: eventInfo.event.extendedProps.code,
                          inqNum: eventInfo.event.extendedProps.inquiryLogId,
                          eventType: eventInfo.event.extendedProps.eventType,
                          ticketId: eventInfo.event.extendedProps.ticketId,
                          topicId:eventInfo.event.extendedProps.topicId
                        },
                      });
                    }
                  }}
                >
                  <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                    <img src={images["meetingRoom"]} style={{ height: 20 }} />
                  </div>
                </Button>
              ) : null}

              {eventInfo.event.extendedProps.eventType == 5 ? (
                <Button
                  variant="none"
                  size={5}
                  onClick={() => {
                    if (eventInfo.event.extendedProps.code != "--") {
                      navigate("/customerDetail", {
                        state: {
                          customerId: eventInfo.event.extendedProps.code,
                          inqNum: eventInfo.event.extendedProps.inquiryLogId,
                          eventType: eventInfo.event.extendedProps.eventType,
                          ticketId: eventInfo.event.extendedProps.ticketId,
                          topicId:eventInfo.event.extendedProps.topicId
                        },
                      });
                    }
                  }}
                >
                  <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                    <img src={images["localCafe"]} style={{ height: 20 }} />
                  </div>
                </Button>
              ) : null}

              {eventInfo.event.extendedProps.eventType == 6 ? (
                <Button
                  variant="none"
                  size={5}
                  onClick={() => {
                    if (eventInfo.event.extendedProps.code != "--") {
                      navigate("/customerDetail", {
                        state: {
                          customerId: eventInfo.event.extendedProps.code,
                          inqNum: eventInfo.event.extendedProps.inquiryLogId,
                          eventType: eventInfo.event.extendedProps.eventType,
                          ticketId: eventInfo.event.extendedProps.ticketId,
                          topicId:eventInfo.event.extendedProps.topicId
                        },
                      });
                    }
                  }}
                >
                  <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                    <img src={images["reportProblem"]} style={{ height: 20 }} />
                  </div>
                </Button>
              ) : null}

              <h3
                style={{ fontWeight: "300", display: "inline", fontSize: 16 }}
              >
                {"管理" == "管理" ? (
                  <Cross2Icon
                    style={{ float: "right" }}
                    onClick={() =>
                      handleDeleteEvent(
                        eventInfo.event.extendedProps.eventId,
                        eventInfo.event.extendedProps.eventGroupId,
                        eventInfo.event.extendedProps.eventRepeat,
                        eventInfo.event.extendedProps.existAttendees,
                        eventInfo.event.extendedProps.registeredVia,
                        eventInfo.event.extendedProps.ticketId,
                        eventInfo.event.extendedProps.google_calendar_id
                      )
                    }
                  />
                ) : null}
                <br />
                {eventInfo.event.extendedProps.holiday == "true" ? null : (
                  <>
                    {eventInfo.event.extendedProps.resourceId}
                    {eventInfo.event.extendedProps.starttime} &emsp; - &emsp;
                    {eventInfo.event.extendedProps.endtime}
                    <br />
                  </>
                )}
                {eventInfo.event.extendedProps.inquiry}
                <br />
                &emsp;
                {eventInfo.event.extendedProps.tempHolding == "true" ? (
                  <>
                    {" "}
                    {eventInfo.event.extendedProps.registered_by_name} <br />
                  </>
                ) : null}
                {eventInfo.event.extendedProps.location}
                <br />
              </h3>
            </div>
          </>
        );
      }

      if (eventInfo.event.extendedProps.registeredVia == "inquiry") {
        // registeredVia:Inquiry

        return (
          <>
            <div
              onClick={handleEditEvent}
              style={{ textAlign: "center", cursor: "pointer" }}
            >
              {eventInfo.event.extendedProps.eventType == 1 ? (
                <Button
                  variant="none"
                  size={5}
                  onClick={() => {
                    navigate("/customerDetail", {
                      state: {
                        customerId: eventInfo.event.extendedProps.code,
                        inquiryCode: eventInfo.event.extendedProps.inquiryLogId,
                        eventType: eventInfo.event.extendedProps.eventType,
                        ticketId: eventInfo.event.extendedProps.ticketId,
                        topicId:eventInfo.event.extendedProps.topicId,
                        google_calendar_id:eventInfo.event.extendedProps.google_calendar_id
                      },
                    });
                  }}
                >
                  <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                    <img src={images["phoneForward"]} style={{ height: 20 }} />
                  </div>
                </Button>
              ) : null}

              {eventInfo.event.extendedProps.eventType == 3 ? (
                <Button
                  variant="none"
                  size={5}
                  onClick={() => {
                    navigate("/customerDetail", {
                      state: {
                        customerId: eventInfo.event.extendedProps.code,
                        inquiryCode: eventInfo.event.extendedProps.inquiryLogId,
                        eventType: eventInfo.event.extendedProps.eventType,
                        ticketId: eventInfo.event.extendedProps.ticketId,
                        topicId:eventInfo.event.extendedProps.topicId
                      },
                    });
                  }}
                >
                  <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                    <img
                      src={images["transferStation"]}
                      style={{ height: 20 }}
                    />
                  </div>
                </Button>
              ) : null}

              {eventInfo.event.extendedProps.eventType == 4 ? (
                <Button
                  variant="none"
                  size={5}
                  onClick={() => {
                    navigate("/customerDetail", {
                      state: {
                        customerId: eventInfo.event.extendedProps.code,
                        inquiryCode: eventInfo.event.extendedProps.inquiryLogId,
                        eventType: eventInfo.event.extendedProps.eventType,
                        ticketId: eventInfo.event.extendedProps.ticketId,
                        topicId:eventInfo.event.extendedProps.topicId
                      },
                    });
                  }}
                >
                  <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                    <img src={images["meetingRoom"]} style={{ height: 20 }} />
                  </div>
                </Button>
              ) : null}

              {eventInfo.event.extendedProps.eventType == 5 ? (
                <Button
                  variant="none"
                  size={5}
                  onClick={() => {
                    navigate("/customerDetail", {
                      state: {
                        customerId: eventInfo.event.extendedProps.code,
                        inquiryCode: eventInfo.event.extendedProps.inquiryLogId,
                        eventType: eventInfo.event.extendedProps.eventType,
                        ticketId: eventInfo.event.extendedProps.ticketId,
                        topicId:eventInfo.event.extendedProps.topicId
                      },
                    });
                  }}
                >
                  <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                    <img src={images["localCafe"]} style={{ height: 20 }} />
                  </div>
                </Button>
              ) : null}

              {eventInfo.event.extendedProps.eventType == 6 ? (
                <Button
                  variant="none"
                  size={5}
                  onClick={() => {
                    navigate("/customerDetail", {
                      state: {
                        customerId: eventInfo.event.extendedProps.code,
                        inquiryCode: eventInfo.event.extendedProps.inquiryLogId,
                        eventType: eventInfo.event.extendedProps.eventType,
                        ticketId: eventInfo.event.extendedProps.ticketId,
                        topicId:eventInfo.event.extendedProps.topicId
                      },
                    });
                  }}
                >
                  <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                    <img src={images["reportProblem"]} style={{ height: 20 }} />
                  </div>
                </Button>
              ) : null}

              <h3
                style={{ fontWeight: "300", display: "inline", fontSize: 16 }}
              >
                
           
       
                <br />
                {eventInfo.event.extendedProps.starttime} &emsp; - &emsp;
                {eventInfo.event.extendedProps.endtime}
                <br />
                {/* Fetch and display the project name next to the project ID */}
{
  (() => {
    const projectId = eventInfo.event.extendedProps.projectId;  // Get the projectId from eventInfo
    const project = projectData.find(proj => proj.id == projectId);  // Find the project with matching id

    // Return the project name or a fallback
    return project ? project.name : "--";
  })()
}
<br />

                {eventInfo.event.title}
                <br />
                &emsp;
                {eventInfo.event.extendedProps.customerName} 様<br />
{/* Display the project ID */}
{/*eventInfo.event.extendedProps.projectId*/} 
{/* Display the total number of projects */}




                {eventInfo.event.extendedProps.addr}
                <br />
                {eventInfo.event.extendedProps.code}
                <br />
              </h3>
            </div>
          </>
        );
      }

      //registeredVia after AfterFollow

      if (eventInfo.event.extendedProps.registeredVia == "afterFollow") {
        return (
          <>
            <div
              onClick={handleEditEvent}
              style={{ textAlign: "center", cursor: "pointer" }}
            >
              <Button
                variant="none"
                size={5}
                onClick={() => {
                  navigate("/AfterFollow", {
                    state: {
                      customerCode: eventInfo.event.extendedProps.code,
                      recNum: eventInfo.event.extendedProps.afterFollowId,
                      
                    },
                  });
                }}
              >
                <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                  <img src={images["transferStation"]} style={{ height: 20 }} />
                </div>
              </Button>

              <h3
                style={{ fontWeight: "300", display: "inline", fontSize: 16 }}
              >
                {"管理" == "管理" ? (
                  <Cross2Icon
                    style={{ float: "right", pointerEvent: "auto" }}
                    onClick={() => {
                      handleDeleteEvent(
                        eventInfo.event.extendedProps.eventId,
                        eventInfo.event.extendedProps.eventGroupId,
                        eventInfo.event.extendedProps.eventRepeat,
                        eventInfo.event.extendedProps.existAttendees,
                        eventInfo.event.extendedProps.registeredVia,
                        eventInfo.event.extendedProps.ticketId,
                        eventInfo.event.extendedProps.google_calendar_id
                      );
                    }}
                  />
                ) : null}
                <br />
                {eventInfo.event.extendedProps.starttime} &emsp; - &emsp;
                {eventInfo.event.extendedProps.endtime}
                <br />
                {eventInfo.event.extendedProps.inquiry}
                <br />
                &emsp;
                {eventInfo.event.extendedProps.customerName} 様<br />
                {eventInfo.event.extendedProps.addr}
                <br />
                {eventInfo.event.extendedProps.code}
                <br />
              </h3>
            </div>
          </>
        );
      }
    } else {
      return (
        <>
          <h2>
            {" "}
            <Center>
              {" "}
              <span id={eventInfo.event.title}></span>
              <Text color="white" size="lg">
                {" "}
                {eventInfo.event.title}
              </Text>
            </Center>{" "}
          </h2>
        </>
      );
    }
  }

  function resourceDetails(resourceInfo) {
    if (resourceInfo.resource.title == "donotdisplay") {
      return <></>;
    } else {
      return (
        <>
          {resourceInfo.resource.title == " " ? (
            <Text color="black" size="xl" weight={800}>
              {" "}
              {resourceInfo.resource._resource.extendedProps.userId}
            </Text>
          ) : (
            <Text color="black" size="xl" weight={800}>
              {" "}
              {resourceInfo.resource.title}
            </Text>
          )}
          
          &emsp;
          <Button
        size='xs'
        style={{
          backgroundColor: 'lightgrey',
          color: 'black',
          fontSize: resourceFontSize,
          marginLeft: '-20px',
          textAlign: 'left' // Align text to the left
        }}
        onClick={() => {
          setCalendarRender(Math.random())
          var a = startDate
          setStartDate(startDate)
          // setStartDate(form2.values.date)
          setDaysInterval(31)
          setEventState([])
          console.log(view)
          setView('month')

          form.setFieldValue('userName', resourceInfo.resource._resource.extendedProps.userId)
          form.setFieldValue('department', resourceInfo.resource._resource.extendedProps.grp_cd)
          setCalendarTitle(resourceInfo.resource.title)
          setCalendarRender(Math.random())
        }}
      >
        月間表示
      </Button>
        </>
      );
    }
  }

  function moreLinkClick(arg) {
    return <>その他のイベント = {arg.num}</>;
  }
  const cssB = { fontSize: "25px", width: "auto" };
  const handleDateClick = (arg) => {
    // bind with an arrow function
console.log(arg)

if (view == 'week') {
    setResourcesData(arg.resource._resource.extendedProps.userId);
  }
  if (view == 'month') {
    setResourcesData(form.values.userName);
  }  
    const dateObj = new Date(arg.dateStr);

 // Format the date for Japan time zone
  var sDate = dateObj.toLocaleString('ja-JP', {
      timeZone: 'Asia/Tokyo',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }).replace(/\//g, '-'); // Replace slashes with dashes

    
    console.log(sDate)
    
    var tpart = "00:00:00"

      var result = sDate.concat("T");
      result = result.concat(tpart);
      var result = sDate.concat("T");
      result = result.concat(tpart);
    
    console.log(result)
    setSelStartDate(new Date(result));
    setOpened(true);
  };



  const handleEditEvent = () => {
  
    // console.log("google_calendar_id",arg.event._def.extendedProps.google_calendar_id)
    // alert(arg.event._def.extendedProps.google_calendar_id);

    if ((arg.event._def.extendedProps.registeredVia == "calendar" ||arg.event._def.extendedProps.registeredVia == "googlecalendar") && !click) {
      setUserEditEventData(arg.event._def);
      setEditPopupOpened(true);
    } else {
      if (
        arg.event._def.extendedProps.registeredVia == "afterFollow" &&
        !click
      ) {
        setUserEditEventData(arg.event._def);

        navigate("/AfterFollow", {
          state: {
            customerCode: arg.event._def.extendedProps.code,
            recNum: arg.event._def.extendedProps.afterFollowId,
            eventId: arg.event._def.extendedProps.eventId,
          },
        });
      } else if (!click) {
  // Ask the user for confirmation
  // const userConfirmed = window.confirm("この予定を対応しますか？\n「はい」を選ぶとカレンダーからチケットが削除されます");
  // console.log(arg.event._def)
  setUserEditEventData(arg.event._def);
  setConfirmPopupOpened(true)

 




} else {
  // Your existing navigation logic

}

    }
  };
  const handleEdit = (arg) => {
    // bind with an arrow function

    setEventArgs(arg);
  };

  const handleDeleteEvent = (
    eventId,
    eventGroupId,
    eventRepeat,
    existAttendees,
    registeredVia,
    ticketId,
    google_calendar_id
  ) => {
   
    click = true;
    if (registeredVia == "calendar"|| registeredVia == "googlecalendar") {
      setEventId(eventId);
      setEventGroupId(eventGroupId);
      setEventRepeat(eventRepeat);
      setExistAttendees(existAttendees);
      setEditPopupOpened(false);
      setTicketId(0)
      setGoogleCalendarId(google_calendar_id)
      setDeletePopupOpened(true);
      
    }
    if (registeredVia == "inquiry") {
      setEventId(eventId);
      setEventGroupId(eventGroupId);
      setEventRepeat(eventRepeat);
      setExistAttendees(existAttendees);
      setTicketId(ticketId)
      setEditPopupOpened(false);
      setDeletePopupOpened(true);
      
      
    }

    if (registeredVia == "afterFollow") {
      setEventId(eventId);
      setEventGroupId(eventGroupId);
      setEventRepeat(eventRepeat);
      setExistAttendees(existAttendees);
      setTicketId(0)
      setEditPopupOpened(false);
      setDeletePopupOpened(true);
      
    }
  };

  function resize() {
    //alert(info.event.end);
  }

  const handleMySchedule = () => {
    
    existingCalendarData = JSON.parse(localStorage.getItem('calendar')) || {};
    existingCalendarData.myEvent = refMySchedule.current.checked;

    // Store the updated calendar data back in localStorage
    localStorage.setItem('calendar', JSON.stringify(existingCalendarData));

    setMyEvent(refMySchedule.current.checked);
    setMyUserId(userId)
    
  };
  // FORM 1 SUBMIT
  

  const handleFilter = () => {
    //console.log(form.values)

    axiosFetch
      .get("formDataEvents.php", {
        params: {
          day: daysInterval,
          userName: form.values.userName,
          department: form.values.department,
          date: form2.values.date,
        },
      })
      .then(function (response) {
        setEventState(response.data);
        //  console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });

    axiosFetch
      .get("formDataResources.php", {
        params: {
          day: daysInterval,
          userName: form.values.userName,
          department: form.values.department,
          date: form2.values.date,
        },
      })
      .then(function (response) {
        setResourcesState(response.data);

        //          console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  //FORM2 SUBMIT

  const handleInterval = (value) => {
    var theNewEndDate = new Date(startDate);
    var days = parseInt(value);

    theNewEndDate.setDate(theNewEndDate.getDate() + days - 1);
    theNewEndDate = theNewEndDate.toISOString().slice(0, 10);

    //  console.log(theNewEndDate)
    setEndDate(theNewEndDate);

    //console.log(form3.values.timeInterval)
    setDaysInterval(value);
  };

  const clearDate = () => {
    setClearDateInput(false);
    setStartDate(new Date().toISOString().slice(0, 10));
    setCalendarDate(new Date().toISOString().slice(0, 10));
    form2.setFieldValue("date", null);
  };
  const handleGotoDate = () => {
    if (form2.values.date != "") {
      setStartDate(form2.values.date);
      

      var theNewEndDate = new Date(form2.values.date);
      theNewEndDate.setDate(theNewEndDate.getDate() + 6);
      theNewEndDate = theNewEndDate.toISOString().slice(0, 10);
      setEndDate(theNewEndDate);
      
    }
  };

  const parseDate = (dateString) => {
    return parse(dateString, 'yyyy-MM-dd', new Date());
  };

  function isValidDate(dateString) {
    const pattern = /^\d{4}-\d{2}-\d{2}$/; // YYYY-MM-DD format
    return pattern.test(dateString);
  }

  const handleClick = () => {
    window.open(`../backend/cti-calendar/calendar-auth/auth/auth.php?port=${port}`);
  };



  return (
    <div ref={drop} className="App" style={{ paddingTop: 15 }}>

{/*
<Button onClick={handleClick} variant="outline" style={{ display: 'inline-block' }}>
      Link your Google calendar 
</Button>
*/}

    <div style={{ display: "flex", flexDirection: matches ? 'row' : 'column', justifyContent: 'center', alignItems: 'center', }}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: matches ? 0 : 10, }}>
              <div style={{ fontSize: matches ? 17 : 10, marginLeft: '80px' }}>
                【絞込み】
              </div>
              <Button
            className="clearsearch"
            style={{ padding: 5, height: 40 }}
            onClick={clearCalendarLocalStorage}
          >
            <Text align='center' weight={700} color='black' style={{ fontSize: matches ? 17 : 10 }}>条件クリア</Text>
          </Button> &emsp;
          
          
              <div>
      
          <form onSubmit={form.onSubmit(handleFilter)}>
        
            <Select
              value={form.values.department && form.values.department}
              clearable
              style={{ width: "140px", display: "inline-block" }}
              placeholder="部門名"
              data={filterGroup}
              onChange={(value) => {
                    existingCalendarData = JSON.parse(localStorage.getItem('calendar')) || {};
                    existingCalendarData.department = value;
                    existingCalendarData.userName = null;
                    localStorage.setItem('calendar', JSON.stringify(existingCalendarData));

                form.setFieldValue("department", value);
                form.setFieldValue("userName", null);
              }}
            />
            &nbsp;
            <Select
              clearable
              value={form.values.userName && form.values.userName}
              style={{ width: "140px", display: "inline-block" }}
              //  data={filterUser}
              data={
                form.values.department !== ""
                  ? filterUser.filter(
                      (user) => user.grp_cd == form.values.department
                    )
                  : []
              }
              onChange={(value) =>
                {
                  existingCalendarData = JSON.parse(localStorage.getItem('calendar')) || {};
                  existingCalendarData.userName = value;
                  localStorage.setItem('calendar', JSON.stringify(existingCalendarData));
                 form.setFieldValue("userName", value)}
               }
            />
          </form>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          <div>
          <form onSubmit={form2.onSubmit(handleGotoDate)}>
            {clearDateInput ? (
              <>
                {" "}
                <DatePicker
                
                  name="date"
                  // defaultValue={isValidDate(localStorageCalendar?.startDate) ? localStorageCalendar.startDate : ''}
                  value={isValidDate(form2.values.date) ? parseDate(form2.values.date) : null}
                  inputFormat="YYYY-MM-DD"
                  onChange={(value) => {
                if(value!=null){
                    const datePart = formatDate(value)
              
                    setCalendarDate(datePart)
                    form2.setFieldValue("date", datePart);
                    setClearDateInput(true);

                    var theNewEndDate = new Date(datePart);
                    theNewEndDate.setDate(theNewEndDate.getDate() + 6);
                    theNewEndDate = theNewEndDate.toISOString().slice(0, 10);
                    setEndDate(theNewEndDate);
                    setStartDate(datePart);
                    
              const existingCalendarData = JSON.parse(localStorage.getItem('calendar')) || {};
              existingCalendarData.clearDateInput = true;
              existingCalendarData.endDate = theNewEndDate;
              existingCalendarData.startDate = datePart;
              localStorage.setItem('calendar', JSON.stringify(existingCalendarData));

                  }
                else{
                  clearDate();
                }}
                }
                />  {" "}
                
          
                
                {" "}
              </>
            ) : (
              <DatePicker
                inputFormat="YYYY-MM-DD"
              value={isValidDate(form2.values.date) ? parseDate(form2.values.date) : null}
                onChange={(value) => {
                    if(value!=null){
                const datePart =formatDate(value)
              
                  form2.setFieldValue("date", datePart);
                  setStartDate(datePart);
                  setClearDateInput(true);
                    setCalendarDate(datePart)
                existingCalendarData = JSON.parse(localStorage.getItem('calendar')) || {};
                existingCalendarData.clearDateInput = true;
                existingCalendarData.startDate = datePart;
                localStorage.setItem('calendar', JSON.stringify(existingCalendarData));


                }
              
            else{
              clearDate();
            }}}
              />
            )}
          </form>
          </div>
          
          
          <div style={{ display: 'flex', marginLeft: matches ? 20 : 10, justifyContent: 'center', alignItems: 'center' }}>
      <input type="checkbox" name="mySchedule" style={{ ...(window.innerWidth < 1000 ? { width: 20, height: 20 } : {}) }}
        checked={myEvent} ref={refMySchedule} onChange={handleMySchedule} />
      <p style={{ ...(window.innerWidth < 1000 && cssB), display: 'inline', marginLeft: matches ? 5 : 0, marginTop:'12px',fontSize: matches ? 17 : 10 }}>マイスケジュール表示</p>
    </div>
    
      
          </div>
          </div>



      <br />
      <div style={{ width: "100%", backgroundColor: "white" }}>
        <Grid columns={30}>
          <Grid.Col span={12}> </Grid.Col>
          <Grid.Col span={17}>
            {" "}
            <div style={{ display: "flex", alignItems: "center" }}>
            
            
              {view == 'week' ? <><Button size="sm"
                className='calendarIterateButton'
                style={{ backgroundColor: "black" }}
                onClick={() => { changeStartDate("prev") }}
              >&#60;&#60;
              </Button>&emsp;
                <Button size="sm"
                  className='calendarIterateButton'
                  style={{ backgroundColor: "black" }}
                  onClick={() => { changeStartDate("prevday") }}
                >&#60;
                </Button> &emsp;</> :
                <Button size="sm"
                  className='calendarIterateButton'
                  style={{ marginLeft: '10px', backgroundColor: "black" }}
                  onClick={() => { changeStartDate("prevmonth") }}
                >&#60;&#60;
                </Button>
              }


              {view == 'month' ? <> <Text
                underline
                style={{
                  marginLeft: '10px',
                  fontSize: 20
                }}
              >
              
                {new Date(startDate).getFullYear()}年{new Date(startDate).getMonth() + 1}月
              </Text></> : <Text underline style={{ marginLeft: '10px', fontSize: matches ? resourceFontSize : 20 }}>

                {new Date(startDate).getFullYear()}年{new Date(startDate).getMonth() + 1}月 {new Date(startDate).getDate()}日 -
                {new Date(endDate).getFullYear()}年{new Date(endDate).getMonth() + 1}月 {new Date(endDate).getDate()}日
                {/*   {startDate} -{endDate}  */}</Text>}

              {view == 'week' ? <><Button size="sm"
                className='calendarIterateButton'
                style={{ marginLeft: '10px', backgroundColor: "black" }}
                onClick={() => { changeStartDate("nextday") }}
              >&#62;
              </Button>
                <Button size="sm"
                  className='calendarIterateButton'
                  style={{ marginLeft: '10px', backgroundColor: "black" }}
                  onClick={() => { changeStartDate("next") }}
                >&#62; &#62;
                </Button>
              </> : <Button size="sm"
                className='calendarIterateButton'
                style={{ marginLeft: '10px', backgroundColor: "black" }}
                onClick={() => { changeStartDate("nextmonth") }}
              >&#62; &#62;
              </Button>}

        
        
            </div>
          </Grid.Col>
          <Grid.Col span={1}> </Grid.Col>
        </Grid>
      </div>

      <br />
{/*
      <Text
        style={{
          backgroundColor: "black",
          color: "white",
          height: "70px",
          width: "100%",
          textAlign: "center",
          textAlignVertical: "center",
          fontWeight: "800",
          fontSize: 20,
          marginRight: 0,
          paddingTop: "20px",
          paddingBottom: "20px",
          marginBottom: "-20px",
        }}
      >
        {calendarDate}
      </Text>
      */}
      
      {view == 'week' ? <Button
      size='sm'
      style={{ backgroundColor: 'lightgrey', color: 'black' }}
      onClick={() => {
        clearDate()
        setCalendarRender(Math.random())
      }}
    >
      今日
    </Button> : null}
    
    
    {
    calendarTitle !== '' ? (
      <>
        <div style={{ width: "100%", display: 'flex', padding: 0 }}>
          <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', fontWeight: 'bold', fontSize: 20 }}>
            {calendarTitle !== '' && filterUser.find(item => item.value === form.values.userName) ? filterUser.find(item => item.value === form.values.userName).label : null}
            &nbsp; &nbsp;  
            {view === 'month' ? (
              <Button
                size='sm'
                style={{ backgroundColor: 'lightgrey', color: 'black' }}
                onClick={() => {
                  var a = startDate
                  setStartDate(startDate)
                  // setStartDate(form2.values.date)
                  setDaysInterval(7)
                  setView('week')
    
                  form.setFieldValue('userName', null)
                  form.setFieldValue('department', null)
                  setCalendarTitle('')
                  setCalendarRender(Math.random())
                }}
              >
                週間表示
              </Button>
            ) : null}
          </div>
        </div>
      </>
    ) : null
  }

      
      {view == 'week' ? null :
        <div className="horizontal-line">
          <div className="space" style={{ color: 'red', borderLeft: '2px solid black' }}>日</div>
          <div className="space">月</div>
          <div className="space">火</div>
          <div className="space">水</div>
          <div className="space">木</div>
          <div className="space">金</div>
          <div className="space" style={{ color: 'blue', borderRight: '2px solid black', }}>土</div>
        </div>
      }

      <FullCalendar
        eventDisplay='block'
        className="full-calendar"
        initialDate={startDate}
        // key={calendarRender}
        key={`${clearDateInput}-${startDate}-${calendarRender}`}

        eventOverlap={false}
        headerToolbar="false"
        initialView={view != 'month' ? "resourceTimelineSevenDays" : "dayGridMonth"}
        // initialView={daysInterval==7?"resourceTimelineSevenDays":"dayGridMonth"}
        views={{
          resourceTimelineSevenDays: {
            type: 'resourceTimeline',
            duration: { days: 7 }, // Display 4 days
            buttonText: '7 Days', // Button text for switching to this view
          },
        }}
        plugins={[resourceTimelinePlugin, dayGridPlugin, interactionPlugin, momentTimezonePlugin]}
        height={deviceWidth > 1000 ? '700px' : '1200px'}
        slotMinTime="00:00"
        slotMaxTime="24:00"
        slotDuration="24:00"
        slotLabelInterval={false}
        slotMinWidth={window.innerWidth > 1000 ? '67' : '100'}
        scrollTime="09:00"
        eventOrder='starttime'
        timeZone="Asia/Tokyo"
        timeZoneParam="Asia/Tokyo"
        eventTextColor='black'
        moreLinkContent={moreLinkClick}
        eventContent={eventDetails}
        events={eventState}
        resources={resourcesState}
        eventClick={handleEdit}
        dateClick={handleDateClick}
        eventMouseEnter={function (info) {
          // get a reference to the event DOM element
          var eventEl = info.el;
          // modify the appearance of the event element
          // eventEl.style.backgroundColor = 'yellow';
          // eventEl.style.borderColor = 'black';
          // display additional information about the event
          var eventObj = info.event;
          // setCalendarDate(event.title)
          if (eventObj.extendedProps.registeredVia == undefined) {
            setCalendarDate(eventObj.title);
          }
        }}
        resourceLabelContent={resourceDetails}
        resourceAreaHeaderContent="ユーザー"
        resourceAreaWidth="20%"
        locale="ja"
        eventResize={resize}
        schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
      />

      <Modal
      centered
        opened={deletePopupOpened}
        onClose={() => setDeletePopupOpened(false)}
        size="xl"
      >
        <DeletePopup
          ticketId={ticketId}
          eventId={eventId}
          eventGroupId={eventGroupId}
          eventRepeat={eventRepeat}
          existAttendees={existAttendees}
          myUserId={myUserId}
          googleCalendarId={googleCalendarId}
          modalControl3={setDeletePopupOpened}
        />
      </Modal>


      <Modal
        opened={confirmPopupOpened}
        onClose={() => setConfirmPopupOpened(false)}
        size="xl"
      >
        <ConfirmPopup
              data={userEditEventData}
              modalControl4={setConfirmPopupOpened}
              userId={userId}
          
        />
      </Modal>



      {editPopupOpened ? (
        <>
          ({" "}
          <Modal
            opened={editPopupOpened}
            onClose={() => setEditPopupOpened(false)}
            size="xl"
          >
            <EditCalendarPopup
              data={userEditEventData}
              modalControl2={setEditPopupOpened}
              userId={userId}
            />
          </Modal>
          )
        </>
      ) : null}

      {opened ? (
        <DraggableBox
          left={boxes.a.left}
          top={boxes.a.top}
          hideSourceOnDrag={true}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Cross1Icon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setOpened(false);
              }}
            />
          </div>
          <CalendarPopup
            selectedStartDate={selStartDate}
            resources={resourcesData}
            modalControl={setOpened}
          />
        </DraggableBox>
      ) : null}
    </div>
  );
};

export default Calendar;
