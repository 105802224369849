import { Button, Checkbox, Col, Grid, Select, Text,Radio,RadioGroup, TextInput,useMantineTheme } from '@mantine/core'
import { DatePicker,isSameDate } from '@mantine/dates'
import { useForm } from '@mantine/hooks'
import { CalendarIcon } from '@radix-ui/react-icons'
import React, { useState,useEffect } from 'react'
import { images } from '../assets'

import { formatDate } from "../dateFormats";
import { useEditEvent} from '../useCalendarData';

import { AxiosFetch } from "../../AxiosFetch";
const EditCalendarPopup = (
    { data,modalControl2,userId }
) => {
    console.log(data)

    const axiosFetch = AxiosFetch();
    const url = window.location.href;
    const urlObject = new URL(url);
    const port = urlObject.port;
    const theme = useMantineTheme();
    const { mutate: editEventCall } = useEditEvent();
    var start=data.extendedProps.starttime;
    var end=data.extendedProps.endtime;
    var isRepeat=data.extendedProps.eventRepeat;
    var registeredVia=data.extendedProps.registeredVia;
    // console.log(registeredVia)

    const [depAndUser,setDepAndUser]=useState([]);
    const [addedUsers, setAddedUsers] = useState([]);

    const myArray = data.extendedProps.rangedate;
    //console.log(myArray)
    const [values, setValues] = useState([]);
    const [typeSelect, setTypeSelect] = useState(0);
    const [users,setUsers] = useState([]);
    const [userGroups,setUserGroups] = useState([]);
    const[userName,setUserName]=useState();
    const [attendees, setAttendees] = useState([]);

    const [changedAttendees,setChangedAttendees]=useState("false");
    useEffect(()=>{
    //  alert(changedAttendees)
    },[changedAttendees])

    const [userRows, setUserRows] = useState([]);
    const [group, setGroupRows] = useState([]);

    useEffect(() => {
        async function getDataUserAll() {
          const APIvalues = {
            customerGroups: [
              {
                groupId: 0,
              },
            ],
          };
    
        //   const response = await axiosFetch.post(`/userList`,JSON.stringify(APIvalues));
        //   setUserRows(response.data);
          const response2 = await axiosFetch.get(`/group/user`);
          setGroupRows(response2.data);
        }
    
        getDataUserAll();

    
      }, []);



    var a;
    if(data.extendedProps.tempHolding=="false"){   a=false;
    }
    else if(data.extendedProps.tempHolding=="true"){

      a=true;
    }
    else{
      a=false;
    }
    var b ;
    if(data.extendedProps.holiday=="false"){

       b=false;
    }
    else if(data.extendedProps.holiday=="true"){  b=true;
    }
    else{
      b=false;
    }
    var c ;
    if(data.allDay==false){

       c=false;
    }
    else if(data.allDay==true){  c=true;
    }
    else{
      c=false;
    }


        const form = useForm({
            initialValues: {
                eventId:data.extendedProps.eventId,
                eventGroupId:data.extendedProps.eventGroupId,
                eventType: data.extendedProps.eventType,
                tempHolding: a,
                holiday: b,
                title: data.extendedProps.inquiry,
                date:[new Date(myArray).toISOString().slice(0, 10)],
                singleDate:new Date(myArray),
                editType:'one',
                //registrants: '',
                //schedule: data.extendedProps.schedule,
                start: data.extendedProps.starttime,
                end: data.extendedProps.endtime,
                registered_by:data.extendedProps.registered_by,
                allDay: c,
                dayRepeat: '',
                timeRepeat: '',
                repeatTill: '',
                place: data.extendedProps.location,
              //  attendee:data.extendedProps.attendee,
                registeredUser:data.extendedProps.name,
                userGroup: null,
                user: null,
                existAttendees:data.extendedProps.existAttendees,
                registeredVia:data.extendedProps.registeredVia,
                google_calendar_id:data.extendedProps.google_calendar_id
            },
            validationRules: {
                //title: (value) => value.trim().length >= 2,
                //registrants: (value) => value.trim().length >= 2,
                //place: (value) => value.trim().length >= 2,
                //groupId: (value) => /^(\s*|\d+)$/.test(value),
            }
        });

        // console.log(form.values);


useEffect(
()=>{
    if(registeredVia!="googlecalendar"){

  axiosFetch.post(`../backend/cti-calendar/userAndDep/fetchAttendees.php?port=${port}`,JSON.stringify({eventGroupId:form.values.eventGroupId,date:data.extendedProps.rangedate,user:data.extendedProps.name}))
      .then(function (response) {
      //    console.log("fetch attendee")
          setAttendees(response.data)
      })
      .catch(function (error) {
          console.log(error);
      });
    }
},[]
)

// useEffect(
//     ()=>{
// if(registeredVia=="googlecalendas"){
//       axiosFetch.post(`../backend/cti-calendar/userAndDep/fetchGoogleAttendees.php?port=${port}`,JSON.stringify({eventGroupId:form.values.eventGroupId,date:data.extendedProps.rangedate,user:data.extendedProps.name,email:data.extendedProps.attendees}))
//           .then(function (response) {
//           //    console.log("fetch attendee")
//               setAttendees(response.data)
//           })
//           .catch(function (error) {
//               console.log(error);
//           });
//         }
//     },[registeredVia]
//     )

useEffect(
()=>{
    const groupIDs = group.map(group => `'${group.groupId}'`);
    
  axiosFetch.get(`../backend/cti-calendar/userAndDep/calendar_fetch.php?port=${port}&&groupIds=${groupIDs}`)
      .then(function (response) {
          setUsers([
              { label: "-", value: "all" },
              ...response.data[0]
          ]);
          setUserGroups(response.data[1]);
      })
      .catch(function (error) {
        // alert("error1")
          console.log(error);
      });
},[group]
)


useEffect(
()=>{

},[]
)

    useEffect(() => {

      setTypeSelect(+data.extendedProps.eventType)
      /*
//alert(data.extendedProps.registered_by)
      axiosFetch.get(`users/fetchUserName.php?port=${port}`, {
                params: {
                userId: data.extendedProps.registered_by,

                }
      })
      .then(function (response) {
        setUserName(response.data)
       console.log(response)

      })



    //


*/
    },[])



    useEffect(() => {

        const groupIDs = group.map(group => `'${group.groupId}'`);
        axiosFetch.get(`../backend/cti-calendar/userAndDep/fetchUserFromDepMst.php?port=${port}&&groupIds=${groupIDs}`)
            .then(function (response) {


                setDepAndUser([
                    { dep:"-", depId:"all", value: "all",label: "-" },
                    ...response.data
                ]);

            })
            .catch(function (error) {
              alert("error2")
              setDepAndUser([
                  { dep:"-", depId:"all", value: "all",label: "-" }
              ]);

                console.log(error);
            });
    }, [group]);

useEffect(()=>{
//  console.log("depAndUser")
//  console.log(depAndUser);

},[depAndUser])
const [depId,setDepId]=useState();
useEffect(
()=>{

  axiosFetch.post(`../backend/cti-calendar/userAndDep/findDep.php?port=${port}`,JSON.stringify({userId:data.extendedProps.name}))
      .then(function (response) {

          setDepId(response.data[0].group_id)

      })
      .catch(function (error) {
          console.log(error);
      });
},[]
)




//const [fetchAttendees,setFetchAttendees]=useState([]);

useEffect(()=>{
const arrayAttendees=[];

if(depAndUser.length!=0){
  for (let j = 0; j < attendees.length; j++){

    console.log("depAndUser",attendees[j])
    console.log(depAndUser);

    const filterAttendees=depAndUser.filter(depAndUser=> (depAndUser.depId==(attendees[j].userGroupValue))&& (depAndUser.value==(attendees[j].userValue)) )

console.log("filterAttendees")
console.log(attendees[j].userValue)
console.log(filterAttendees)

if(filterAttendees.length>0){

  arrayAttendees.push({
    userGroupValue: filterAttendees[0].depId,
    userGroupLabel: filterAttendees[0].dep,
    userValue: filterAttendees[0].value,
    userLabel: filterAttendees[0].label,
    email: filterAttendees[0].email
  });
}

  }
  console.log("---")
  console.log(depAndUser)
  console.log(attendees)
  console.log(arrayAttendees)
}

//setFetchAttendees(arrayAttendees);
setAddedUsers(arrayAttendees)


},[attendees,depAndUser])



//alert(c)


const handleAllDay=(event)=>{
      //alert(event.currentTarget.checked)

if(event.currentTarget.checked){
  form.setFieldValue('allDay',event.currentTarget.checked)
  form.setFieldValue('start','')
  form.setFieldValue('end','')
}
else{

form.setFieldValue('allDay',event.currentTarget.checked)
form.setFieldValue('start', start)
form.setFieldValue('end', end)
}
    }



    const handleTempHolding=(event)=>{
form.setFieldValue('tempHolding', event.currentTarget.checked)
if(event.currentTarget.checked){
  form.setFieldValue('title', '【仮】')
//  form.setFieldValue('holiday',false)

}
else{
  form.setFieldValue('title',"")
//  form.setFieldValue('holiday', '')

}
    }



        const inputFormat = values
            .map((date) => new Date(date).toLocaleDateString("en-US"))
            .join(", ");

        const dayStyle = (date) => {
            if (values.some((day) => isSameDate(date, day))) {
                return {
                    backgroundColor: theme.colors.blue[0],
                    color: theme.colors.blue[9]
                };
            }
            return null;
        };

        const handleDayPick = (value) => {
            const formattedDate = formatDate(value);
            setValues((current) => {
                if (current) {
                    if (current.some((day) => isSameDate(value, day))) {
                        return current.filter((day) => !isSameDate(value, day));
                    }
                }

                let formDateValue = []
                current.map((dateValue) => {
                    formDateValue.push(formatDate(dateValue))
                })

                form.setFieldValue('date', [...formDateValue, formattedDate]);

                return [...current, value];
            });
        };

    const handleHoliday=(event)=>{
        form.setFieldValue('holiday', event.currentTarget.checked)
        if(event.currentTarget.checked){
        //  form.setFieldValue('tempHolding',false)
        //  form.setFieldValue('title', '')

          form.setFieldValue('allDay', event.currentTarget.checked)
          form.setFieldValue('start','')
          form.setFieldValue('end','')
        }
        else{
          form.setFieldValue('allDay', event.currentTarget.checked)
          form.setFieldValue('start', start)
          form.setFieldValue('end', end)
        }
    }




        const addUser = () => {
            if (form.values.userGroup && form.values.user) {
                const userGroupName = userGroups.find(user => user.value === form.values.userGroup);
                const userName = users.find(user => user.value === form.values.user);
                setChangedAttendees("true");

                if(userName.value=="all"){



                  setAddedUsers(prev => ([
                      ...prev,
                      {
                          userGroupValue: userGroupName.value,
                          userGroupLabel: userGroupName.label,
                          userValue: userName.value,
                          userLabel: userName.label,
                          email: userName.email
                      }
                  ]))


                }
                else{

                  setAddedUsers(prev => ([
                      ...prev,
                      {
                          userGroupValue: userGroupName.value,
                          userGroupLabel: userGroupName.label,
                          userValue: userName.value,
                          userLabel: userName.label,
                          email: userName.email
                      }
                  ]))
                }



                form.values.userGroup = null;
                form.values.user = null;
            }
        }

        const removeUser = (userInfo) => {
          setChangedAttendees("true");
            setAddedUsers(prev => (
                prev.filter(user => user.userValue !== userInfo.userValue)
            ))
        }


    const handleEditEvent = () => {
        // console.log(form.values);
        // alert(form.values.userGroup)
let changed="false";
        const updatedUser=[];
                //  alert(addedUsers.length)
                 for (let i = 0; i < addedUsers.length; i++) {
                //   alert(addedUsers[i].userGroupValue)

                if(addedUsers[i].userValue!="all"){
                  updatedUser.push({
                      userGroupValue: addedUsers[i].userGroupValue,
                      userGroupLabel: addedUsers[i].userGroupLabel,
                      userValue: addedUsers[i].userValue,
                      userLabel: addedUsers[i].userLabel,
                      email: addedUsers[i].email
                  })
                }
                else {

                    //   const filterUser=depAndUser.filter(depAndUser=> (depAndUser.depId.includes(addedUsers[i].userGroupValue)))
                      const filterUser = depAndUser.filter(depAndUser => 
                        String(depAndUser.depId) === String(addedUsers[i].userGroupValue)
                    );
                    
                      for (let j = 0; j < filterUser.length; j++)
                        {
                        updatedUser.push({
                            userGroupValue: filterUser[j].depId,
                            userGroupLabel: filterUser[j].dep,
                            userValue: filterUser[j].value,
                            userLabel: filterUser[j].label,
                            email: addedUsers[j].email,
                        })
                      }
                }
                 }

                 if(form.values.userGroup!=null && form.values.user!=null){


                           if(form.values.user!="all"){
                             changed="true"

                            //   const filterUser=depAndUser.filter(depAndUser=> (depAndUser.depId.includes(form.values.userGroup))&& (depAndUser.value.includes(form.values.user)))
                            const filterUser = depAndUser.filter(depAndUser => 
                                String(depAndUser.depId).includes(String(form.values.userGroup)) &&
                                String(depAndUser.value).includes(String(form.values.user))
                            );
                            
                            updatedUser.push({
                                   userGroupValue: filterUser[0].depId,
                                   userGroupLabel: filterUser[0].dep,
                                   userValue: filterUser[0].value,
                                   userLabel: filterUser[0].label,
                                   email: filterUser[0].email
                               })
                             }
                             else {
                               changed="true"


                                //    const filterUser=depAndUser.filter(depAndUser=> (depAndUser.depId.includes(form.values.userGroup)))
                                const filterUser = depAndUser.filter(depAndUser => 
                                    String(depAndUser.depId).includes(String(form.values.userGroup))
                                );
                                
                                for (let j = 0; j < filterUser.length; j++)
                                     {

                                     updatedUser.push({
                                         userGroupValue: filterUser[j].depId,
                                         userGroupLabel: filterUser[j].dep,
                                         userValue: filterUser[j].value,
                                         userLabel: filterUser[j].label,
                                         email: filterUser[j].email
                                     })
                                   }
                             }
                             setChangedAttendees("true");
                 }



                //  console.log("updatedUser");


        modalControl2(false);
//alert(changed)
var singleDate = form.values.singleDate.toString().slice(0, 10);

if(changed=="true"){
  //alert("inside changed= true")
 console.log(updatedUser);
  const eventParam = {
    editType:form.values.editType,
    eventGroupId:form.values.eventGroupId,

    eventId:form.values.eventId,
    allDay: form.values.allDay,
    end: form.values.end,
    eventType: form.values.eventType,
    place: form.values.place,
    date: singleDate,
    start: form.values.start,
    tempHolding: form.values.tempHolding,
    holiday: form.values.holiday,
    title: form.values.title,

    repeatTill: form.values.repeatTill.toString(),
    schedule: form.values.date,
    dayRepeat: form.values.dayRepeat,
    registeredUser:form.values.registeredUser,
    registeredBy: userId,
    myUserId:userId,
    addedUsers:updatedUser,
    changedAttendees:"true",
    existAttendees:form.values.existAttendees,
    prevDate:data.extendedProps.rangedate,
    isRepeat:isRepeat,
    department:depId,
    customerCode:"顧客情報が無い予定",
    registeredVia:form.values.registeredVia,
    google_calendar_id:data.extendedProps.google_calendar_id,
    port:port
  
  }
 editEventCall(eventParam,port);
}
else{


  const eventParam = {
    editType:form.values.editType,
    eventGroupId:form.values.eventGroupId,

    eventId:form.values.eventId,
    allDay: form.values.allDay,
    end: form.values.end,
    eventType: form.values.eventType,
    place: form.values.place,
    date: singleDate,
    start: form.values.start,
    tempHolding: form.values.tempHolding,
    holiday: form.values.holiday,
    title: form.values.title,

    repeatTill: form.values.repeatTill.toString(),
    schedule: form.values.date,
    dayRepeat: form.values.dayRepeat,
    registeredUser:form.values.registeredUser,
    registeredBy: userId,
    myUserId:userId,
    addedUsers:updatedUser,
    changedAttendees:changedAttendees,
    existAttendees:form.values.existAttendees,
    prevDate:data.extendedProps.rangedate,
    isRepeat:isRepeat,
    department:depId,
    customerCode:"顧客情報が無い予定",
    registeredVia:form.values.registeredVia,
    google_calendar_id:data.extendedProps.google_calendar_id,
    port:port
  }
 editEventCall(eventParam,port);
}




    }

    const EventTypeButton = ({ type, name, icon }) => {
        return (
            <Button
                variant={typeSelect == type ? 'filled' : 'outline'}
                size={25}
                style={{ width: 70, padding: 0 }}
                onClick={() => {
                    form.setFieldValue('eventType', type);
                    typeSelect === type ? setTypeSelect(0) : setTypeSelect(type);
                }}
            >
                <div style={{ paddingTop: 10, paddingBottom: 10 }}>
                    <img src={images[icon]} style={{ height: 25 }} />
                    <Text>{name}</Text>
                </div>
            </Button>
        )
    }



    return (
        <div>
            <form onSubmit={form.onSubmit(handleEditEvent)}>
            {isRepeat=="true" ?<Grid align='center'>
                <Col span={5}> この予定は繰り返し登録されています </Col>

                <Col span={7}>
                <RadioGroup
                    value={form.values.editType}

                    onChange={(value) => { form.setFieldValue('editType', value) }}
                >
                    <Radio value="one">この予定のみ </Radio>
                    <Radio value="all">すべての予定を編集する</Radio>
                </RadioGroup>
                </Col>
            </Grid> :null}


                <Grid align='center'>
                    <Col span={3}>
                        <Text>予定種別</Text>
                    </Col>
                    <Col span={8}>
                        <Grid>
                            <Col span={2} >
                                <EventTypeButton type={1} name='電話' icon='phoneForward' />
                            </Col>

                            <Col span={2}>
                                <EventTypeButton type={3} name='訪問' icon='transferStation' />
                            </Col>
                            <Col span={2}>
                                <EventTypeButton type={4} name='会議' icon='meetingRoom' />
                            </Col>
                            <Col span={2}>
                                <EventTypeButton type={5} name='休み' icon='localCafe' />
                            </Col>
                            <Col span={2}>
                                <EventTypeButton type={6} name='その他' icon='reportProblem' />
                            </Col>
                        </Grid>
                    </Col>
                </Grid>

                <Grid align='center'>
                    <Col span={1}></Col>
                    <Col span={4}>
                        <Checkbox
                            label="スケジュール仮押さえ"
                            style={{ padding: 5 }}
                            checked={form.values.tempHolding}

                            onChange={handleTempHolding}
                        />
                    </Col>
                    <Col span={3}>
                        <Checkbox
                            label="休日登録"
                            style={{ padding: 5 }}
                            checked={form.values.holiday}
                            onChange={handleHoliday}

                        />
                    </Col>
                </Grid>

                <Grid align='center'>
                    <Col span={2}>
                        <Text>タイトル</Text>
                        <Text>(問合せ件名）</Text>
                    </Col>
                    <Col span={9}>
                        <TextInput
                            required
                            value={form.values.title}
                            onChange={(event) => form.setFieldValue('title', event.currentTarget.value)}
                            onFocus={() => form.setFieldError('title', false)}
                            error={form.errors.title && 'Title should include at least 2 characters'}
                        />
                    </Col>
                </Grid>
                <Grid align='center'>
                    <Col span={2}>
                        <Text>登録者</Text>
                    </Col>
                    <Col span={9}>
                        <TextInput
                            disabled
                            value={userName}
                          //  onChange={(event) => form.setFieldValue('registrants', event.currentTarget.value)}
                          //  onFocus={() => form.setFieldError('registrants', false)}
                            error={form.errors.registrant && 'Registrants should include at least 2 characters'} />
                    </Col>
                </Grid>

                <Grid align='center'>
                    <Col span={2}>
                        <Text>スケジュール</Text>
                    </Col>
                    <Col span={10}>
                        <Grid align='center'>
                            <Col span={4}>


   {form.values.editType == 'all'?  <DatePicker
       closeCalendarOnChange={false}
       inputFormat={inputFormat}
       value={form.values.date}
       onChange={handleDayPick}
       dayStyle={dayStyle}
       onClear={() => {
           form.setFieldValue('date', []);
           setValues([]);
       }}
       dropdownType="modal"
       multiline
       styles={{
           selected: {
               backgroundColor: "transparent",
               color: "unset"
           }
       }}
       icon={<CalendarIcon style={{ color: 'black' }} />}
   />  :<DatePicker
         dropdownType="modal"
         defaultValue={form.values.singleDate}
         onChange={(value) => {
             form.setFieldValue('singleDate', formatDate(value))
         }}
         icon={<CalendarIcon style={{ color: 'black' }} />}
     />
                                     }



{/*


*/}

                            </Col>
                            <Col span={1}>
                                <Text>開始</Text>
                            </Col>
                            <Col span={2}>
                              <input type='time'
                                    Value={form.values.start}

                                    onChange={(e) => form.setFieldValue('start', e.currentTarget.value)}
                                />
                            </Col>
                            <Col span={1}>
                                <Text>終了</Text>
                            </Col>
                            <Col span={2}>
                              <input type='time'
                                //    defaultValue={new Date(selectedStartDate.getTime() + 2 * 60 * 60 * 1000).toLocaleTimeString()}
                                    value={form.values.end}
                                    onChange={(e) => form.setFieldValue('end', e.currentTarget.value)}
                                />
                            </Col>
                            <Col span={2}>
                                <Checkbox
                                label="終日"
                                checked={form.values.allDay}
                                onChange={handleAllDay}
                                />
                            </Col>
                        </Grid>
                        <Grid align='center'>
                            <Col span={3}>
                                <Checkbox label="曜日リピート" checked={form.values.dayRepeat} onChange={(event) => form.setFieldValue('dayRepeat', event.currentTarget.checked)} />
                            </Col>
                            <Col span={3}>
                                <Checkbox label="時刻リピート" checked={form.values.timeRepeat} onChange={(event) => form.setFieldValue('timeRepeat', event.currentTarget.checked)} />
                            </Col>
                            <Col span={2} style={{ padding: 0 }}>
                                <Text>リピート終点</Text>
                            </Col>
                            <Col span={3}>
                                <DatePicker
                                    dropdownType="modal"
                                    defaultValue=""
                                    onChange={(value) => {
                                        form.setFieldValue('repeatTill', formatDate(value))
                                    }}
                                    icon={<CalendarIcon style={{ color: 'black' }} />}
                                />
                            </Col>
                        </Grid>
                    </Col>
                </Grid>

                <Grid align='center'>
                    <Col span={2}>
                        <Text>場所</Text>
                    </Col>
                    <Col span={9}>
                        <TextInput
                            value={form.values.place}
                            onChange={(event) => form.setFieldValue('place', event.currentTarget.value)}
                            onFocus={() => form.setFieldError('place', false)}
                            error={form.errors.place && 'Place should include at least 2 characters'}
                        />
                    </Col>
                </Grid>
                <Grid align='center'>
                    <Col span={2}>
                        <Text>出席者</Text>
                    </Col>
                    <Col span={10}>
                        <Grid align='center'>
                            <Col span={1}>
                            <Button
                                style={{ backgroundColor: '#0844A4' }}
                                onClick={addUser}
                            >+</Button>
                            </Col>
                            <Col span={3}>
                            <Select

                                style={{ padding: 10 }}
                                placeholder="部門名"
                                data={userGroups}
                                defaultValue={form.values.userGroup}
                                onChange={value => 
                                    {
                                    form.setFieldValue('userGroup', value)
                                    form.setFieldValue('user', '')
                                    }

                                }
                            />
                            </Col>
                            <Col span={3}>
                            {depAndUser &&(  <Select

                                  style={{ padding: 10 }}

                                  data={ depAndUser && depAndUser.filter(depAndUser=> (depAndUser.depId===(form.values.userGroup)) || (depAndUser.depId.toString().includes("all"))  ) }
                                  value={form.values.user}
                                  onChange={value => form.setFieldValue('user', value)}
                              />)}

                            </Col>
                        </Grid>
                        {console.log(addedUsers)}
                        {addedUsers.map((userInfo, index) => (

                            <Grid key={index}>
                                <Col span={1} >
                                    <Button
                                        style={{ backgroundColor: 'red' }}
                                        onClick={() => removeUser(userInfo)}
                                    >X</Button>
                                </Col>
                                <Col span={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Text>{userInfo.userGroupLabel}</Text>
                                </Col>
                                <Col span={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Text>{userInfo.userLabel}</Text>
                                </Col>
                            </Grid>
                        ))}
                    </Col>
                </Grid>
                <Grid>
                    <Col span={9}></Col>
                    <Col span={2}>
                    {"管理"=="管理"?<Button
                        fullWidth
                        radius='xl'
                        style={{ backgroundColor: '#0844A4' }}
                        type='submit'
                    >
                        保存
                    </Button>:null}

                    </Col>
                </Grid>
            </form>

        </div>
    )
}

export default EditCalendarPopup
