import React, { useRef, useState } from "react";
import {
  Card,
  Grid,
  Typography,
  CardContent,
  Tooltip,
  TextField,
} from "@mui/material";
import CustomTextField from "../../formikInputs/CustomTextField";
import CustomSelect from "../../formikInputs/CustomSelect";
import GroupSelect from "../../formikInputs/GroupSelect";
import UserSelectByGroup from "../../formikInputs/UserSelectByGroup";
import { useTranslation } from "react-i18next";
import ProjectSelectByGroup from "../../formikInputs/ProjectSelectByGroup";
import CustomAutoComplete from "../../formikInputs/CustomAutoComplete";
import { DuplicateTelephoneSelectForCustDetail } from "./custItems/DuplicateTelephoneSelectForCustDetail";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { FormikContext } from "formik";
import axios from "axios";
import CustomMultiSelectCheck from "../../formikInputs/CustomMultiSelectCheck";

export default function CustForm(props) {
  const { t } = useTranslation();
  let readMode = props.mode === "read" ? "read" : "";
  const sizeFlag = props.type === "new" ? 4 : 3;
  const [zFlag, setZFlag] = useState(true);
  const zipcodeRef = useRef();

  //do not change these values.
  const variableListConsultation = [
    { value: 1, name: "保険" },
    { value: 2, name: "資産運用について" },
    { value: 3, name: "老後資金について" },
    { value: 4, name: "教育費" },
    { value: 5, name: "相続・贈与" },
  ];

  //do not change these values.
  const variableListContactTime = [
    { value: 1, name: "平日：午前中（10-12時）" },
    { value: 2, name: "平日：午後（12-18時）" },
    { value: 3, name: "平日：夕方以降（18時以降）" },
    { value: 4, name: "土日：午前中（10-12時）" },
    { value: 5, name: "土日：午後（12-18時）" },
    { value: 6, name: "土日：夕方以降（18時以降）" },
  ];

  return (
    <>
      <Card sx={{ minWidth: 275, height: "100%" }} elevation={4}>
        <CardContent>
          <Typography
            sx={{ fontSize: 15 }}
            color="black"
            style={{ fontWeight: "bold" }}
            align="center"
            gutterBottom
          >
            {t("Customer Information")}
            <Tooltip
              fontSize="small"
              title={t(
                `"Basic customer information" . There are some minimum required items. Items in this table cannot be increased or decreased .`
              )}
            >
              <HelpOutlineIcon />
            </Tooltip>
          </Typography>
          <Grid container columnSpacing={0.4} rowSpacing={0.9}>
            {sizeFlag === 3 ? (
              <Grid item xs={sizeFlag}>
                <CustomTextField
                  mode={"read"}
                  data={{ name: "customerId", label: `${t("SPT ID")}` }}
                />
              </Grid>
            ) : (
              <></>
            )}
            <Grid item xs={sizeFlag + 0.65}>
              <CustomTextField
                mode={readMode}
                data={{ name: "customerLastName", label: `${t("Last Name")}` }}
              />
            </Grid>
            <Grid item xs={sizeFlag + 0.65}>
              <CustomTextField
                mode={readMode}
                data={{
                  name: "customerFirstName",
                  label: `${t("First Name")}`,
                }}
              />
            </Grid>
            <Grid item xs={sizeFlag - 1.3}>
              <CustomTextField
                //mode={readMode}
                mode={"read"}
                data={{ name: "customerAge", label: `${t("Age")}` }}
              />
            </Grid>

            <Grid item xs={3.5}>
              <CustomTextField
                mode={readMode}
                data={{ name: "customerLastRuby", label: `${t("Last Ruby")}` }}
              />
            </Grid>
            <Grid item xs={3.5}>
              <CustomTextField
                mode={readMode}
                data={{
                  name: "customerFirstRuby",
                  label: `${t("First Ruby")}`,
                }}
              />
            </Grid>

            <Grid item xs={5}>
              <CustomTextField
                mode={readMode}
                data={{
                  name: "customerBirthday",
                  label: `${t("Date of Birth")}`,
                }}
                type="date"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={7}>
              <CustomTextField
                mode={readMode}
                type="email"
                data={{ name: "customerEmail", label: `${t("Email")}` }}
              />
            </Grid>

            <Grid item xs={5}>
              <CustomSelect
                mode={readMode}
                data={{
                  name: "customerSex",
                  label: `${t("Sex")}`,
                  list: [
                    { value: "Male", name: `${t("Male")}` },
                    { value: "Female", name: `${t("Female")}` },
                    { value: "Other", name: `${t("Other")}` },
                  ],
                }}
              />
            </Grid>
            <Grid item xs={7}>
              {/* <CustomTextField
                mode={readMode}
                data={{
                  name: "telephone[0].telNum",
                  label: `${t("Telephone")}`,
                }}
              /> */}

              {props.type === "detail" ? (
                // <CustomSelect
                //   mode={""}
                //   data={{
                //     name: "telephoneNew",
                //     label: `${t("Telephone")}`,
                //     list: props.telephoneList,
                //   }}
                // />
                <DuplicateTelephoneSelectForCustDetail
                  customerDetails={props.customerDetails}
                  duplicateTelephoneCustArr={props.duplicateTelephoneCustArr}
                />
              ) : props.type === "edit" ? (
                <CustomAutoComplete
                  mode={readMode}
                  data={{
                    name: "telephoneNew",
                    label: `${t("Telephone")}`,
                  }}
                />
              ) : (
                <CustomAutoComplete
                  mode={readMode}
                  data={{
                    name: "telephone",
                    label: `${t("Telephone")}`,
                  }}
                />
              )}
            </Grid>
            <Grid item xs={5}>
              <CustomTextField
                mode={readMode}
                //type="number"
                inputRef={zipcodeRef}
                data={{
                  name: "customerZipCode",
                  label: `${t("ZipCode")}`,
                }}
              />
            </Grid>

            <FormikContext.Consumer>
              {(formikContext) => {
                // console.log(formikContext, "fffffff");
                const zipcode = formikContext.values.customerZipCode;
                if (
                  zFlag &&
                  zipcode !== formikContext.initialValues.customerZipCode
                ) {
                  const fetchZipcodeData = async () => {
                    const baseUrl = "https://zipcloud.ibsnet.co.jp/api/search";
                    const url = `${baseUrl}?zipcode=${zipcode}`;
                    try {
                      const response = await axios.get(url);
                      console.log(response, "zipcode API response");
                      if (
                        response.data.status === 200 &&
                        response.data.results
                      ) {
                        console.log(response.data.results, "success result");
                        formikContext.setFieldValue(
                          "customerAddress1",
                          response.data.results[0].address1
                        );
                        formikContext.setFieldValue(
                          "customerAddress2",
                          response.data.results[0].address2
                        );
                        formikContext.setFieldValue(
                          "customerAddress3",
                          response.data.results[0].address3
                        );
                      } else {
                        console.log("error:", response.data.message);
                      }
                    } catch (error) {
                      console.error("Error:", error.message);
                    }
                    return null;
                  };
                  if (zipcode.length === 7 && zipcode.indexOf("-") === -1) {
                    setZFlag(false);
                    fetchZipcodeData();
                    setTimeout(() => {
                      zipcodeRef.current.focus(); // Maintain focus on the ZipCode input field
                    }, 0);
                  } else if (
                    zipcode.length === 8 &&
                    zipcode.indexOf("-") !== -1
                  ) {
                    setZFlag(false);
                    fetchZipcodeData();
                    setTimeout(() => {
                      zipcodeRef.current.focus(); // Maintain focus on the ZipCode input field
                    }, 0);
                  }
                } else if (!zFlag) {
                  if (
                    (zipcode.indexOf("-") === -1 && zipcode.length < 7) ||
                    (zipcode.indexOf("-") !== -1 && zipcode.length < 8)
                  ) {
                    setZFlag(true);
                    setTimeout(() => {
                      zipcodeRef.current.focus(); // Maintain focus on the ZipCode input field
                    }, 0);
                  }
                }

                return (
                  <>
                    <Grid item xs={6}>
                      <Tooltip
                        title={formikContext.values.customerAddress1}
                        placement="bottom"
                        arrow
                      >
                        <TextField
                          fullWidth
                          id="outlined-controlled"
                          label={t("Address 1")}
                          value={formikContext.values.customerAddress1}
                          onChange={(event) => {
                            formikContext.setFieldValue(
                              "customerAddress1",
                              event.target.value
                            );
                          }}
                          InputProps={{
                            readOnly: readMode === "read" ? true : false,
                          }}
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                      <Tooltip
                        title={formikContext.values.customerAddress2}
                        placement="bottom"
                        arrow
                      >
                        <TextField
                          fullWidth
                          id="outlined-controlled"
                          label={t("Address 2")}
                          value={formikContext.values.customerAddress2}
                          onChange={(event) => {
                            formikContext.setFieldValue(
                              "customerAddress2",
                              event.target.value
                            );
                          }}
                          InputProps={{
                            readOnly: readMode === "read" ? true : false,
                          }}
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                      <Tooltip
                        title={formikContext.values.customerAddress3}
                        placement="bottom"
                        arrow
                      >
                        <TextField
                          fullWidth
                          id="outlined-controlled"
                          label={t("Address 3")}
                          value={formikContext.values.customerAddress3}
                          onChange={(event) => {
                            formikContext.setFieldValue(
                              "customerAddress3",
                              event.target.value
                            );
                          }}
                          InputProps={{
                            readOnly: readMode === "read" ? true : false,
                          }}
                        />
                      </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                      <Tooltip
                        title={formikContext.values.customerAddress4}
                        placement="bottom"
                        arrow
                      >
                        <TextField
                          fullWidth
                          id="outlined-controlled"
                          label={t("Address 4")}
                          value={formikContext.values.customerAddress4}
                          onChange={(event) => {
                            formikContext.setFieldValue(
                              "customerAddress4",
                              event.target.value
                            );
                          }}
                          InputProps={{
                            readOnly: readMode === "read" ? true : false,
                          }}
                        />
                      </Tooltip>
                    </Grid>
                  </>
                );
              }}
            </FormikContext.Consumer>

            <Grid item xs={6}>
              <CustomSelect
                mode={readMode}
                data={{
                  name: "storeUsed",
                  label: `${t("Stores Used")}`,
                  list: [
                    {
                      value: "mobile store",
                      name: `${t("Mobile store")}`,
                    },
                    // {
                    //   value: "town store",
                    //   name: `${t("Town store")}`,
                    // },
                    {
                      value: "itabashi mobile store",
                      name: `${t("Itabashi store")}`,
                    },
                    {
                      value: "yamato tsuruma store",
                      name: `${t("Aeon Mobile Yamato Tsuruma Store")}`,
                    },
                    {
                      value: "yachiyo midorigaoka store",
                      name: `${t("Aeon Mobile Yachiyo Midorigaoka Store")}`,
                    },
                    {
                      value: "murayama mobile store",
                      name: `${t("Aeon Murayama Mobile Store")}`,
                    },
                  ],
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomSelect
                mode={readMode}
                data={{
                  name: "customerMaritalStatus",
                  label: `${t("Customer Marital Status")}`,
                  list: [
                    {
                      value: true,
                      name: `${t("Married")}`,
                    },
                    {
                      value: false,
                      name: `${t("UnMarried")}`,
                    },
                  ],
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomSelect
                mode={readMode}
                data={{
                  name: "customerChildren",
                  label: `${t("Customer Children")}`,
                  list: [
                    {
                      value: true,
                      name: `${t("Children")}`,
                    },
                    {
                      value: false,
                      name: `${t("No Children")}`,
                    },
                  ],
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomSelect
                mode={readMode}
                data={{
                  name: "householdIncome",
                  label: `${t("Household Income")}`,
                  list: [
                    {
                      value: "300",
                      name: `${t("less than 300")}`,
                    },
                    {
                      value: "500",
                      name: `${t("300〜500")}`,
                    },
                    {
                      value: "700",
                      name: `${t("500〜700")}`,
                    },
                    {
                      value: "1000",
                      name: `${t("700〜1000")}`,
                    },
                    {
                      value: "9999",
                      name: `${t("more than 1000")}`,
                    },
                  ],
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <CustomMultiSelectCheck
                data={{
                  name: `contentsOfConsultation`,
                  label: t("Contents Of Consultation"),
                  list: variableListConsultation,
                }}
                mode={readMode}
              />
            </Grid>

            <Grid item xs={6}>
              <CustomMultiSelectCheck
                data={{
                  name: `preferredContactTime`,
                  label: t("Preferred Contact Time"),
                  list: variableListContactTime,
                }}
                mode={readMode}
              />
            </Grid>

            <Grid item xs={6}>
              <CustomTextField
                mode={readMode}
                data={{
                  name: "customerBusinessType",
                  label: `${t("Profession")}`,
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <CustomTextField
              //always read mode
                mode='read'
                data={{
                  name: "customerRegisterDateTime",
                  label: `${t("Created On")}`,
                }}
                type="datetime-local"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>

            <Grid item xs={6}>
              <UserSelectByGroup
                mode={readMode}
                name="customerFirstUserId"
                label={t("First User Name")}
                groupId={props.groupId}
                originalGroup={props.originalGroup || 0}
              />
            </Grid>
            <Grid item xs={6}>
              <UserSelectByGroup
                mode={readMode}
                name="customerSecondUserId"
                label={t("Second User Name")}
                groupId={props.groupId}
                originalGroup={props.originalGroup || 0}
              />
            </Grid>
            <Grid item xs={6}>
              <GroupSelect mode={readMode} name="customerGroup.groupId" />
            </Grid>
            <Grid item xs={6}>
              {/* <ProjectSelect mode={readMode} name="project.id" /> */}
              <ProjectSelectByGroup
                mode={readMode}
                name="project.id"
                label={t("Project")}
                groupId={props.groupId}
              />
            </Grid>

            <Grid item xs={6}>
              <CustomTextField
                mode={readMode}
                disabled
                data={{
                  name: "customerRegisterUserId",
                  label: `${t("Author")}`,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                mode={readMode}
                disabled
                data={{
                  name: "customerModifyUserId",
                  label: `${t("Last Updated by")}`,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                mode={readMode}
                multiline
                minRows={3}
                data={{ name: "customerMemo1", label: `${t("Memo 1")}` }}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextField
                mode={readMode}
                multiline
                minRows={3}
                data={{ name: "customerMemo2", label: `${t("Memo 2")}` }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
